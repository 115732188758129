import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import DragAndDropImageUploader from "../widgets/DragAndDropImageUploader"
import { IconButton, Switch, Select, MenuItem, FormControl } from '@mui/material';
import BackIcon from "../../img/icon/backIcon2.svg"
import GenericButton from '../widgets/GenericButton';
import { addEditFeedInfo, deleteFeed } from '../../utilities/requests';
import APIResult from '../../configs/APIResult';
import "./AddFeedPage.css";
import DeleteIcon from "../../img/icon/delete_icon.svg"
import MessageDialog from '../dialogs/MessageDialog';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import { debugPrint } from '../../utilities/Utilities';



const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
class AddFeed extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            setDefaultImage: false,
            feedID: null,
            editing: true,
            title: "",
            content: "",
            emergencyType: 0,
            publish: false,
            coverImage: "", // HTTPS / B64
            feeds: [],
            imageCheck:false,

            showAlertDialog: false,
            alertDialogTitle: 'Message Dialog Title',
            alertDialogMessage: 'Message Dialog Message',
            alertDialogAction: null,
            showAlertDialogButton: true,

            showConfirmationDialog: false,
            confirmationDialogTitle: 'Message Dialog Title',
            confirmationDialogMessage: 'Message Dialog Message',
            confirmationDialogAction: null,
            showConfirmationDialogButton: true,
        };

        this.backButton = this.backButton.bind(this)
        this.getFeedPageInfo = this.getFeedPageInfo.bind(this)
        this.updateTitle = this.updateTitle.bind(this)
        this.updateContent = this.updateContent.bind(this)
        this.addEditFeedInfo = this.addEditFeedInfo.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.switchChanger = this.switchChanger.bind(this)
        this.saveContent = this.saveContent.bind(this)
        this.isEditing = this.isEditing.bind(this)
        this.deleteFeed = this.deleteFeed.bind(this)
        this.enableAlertDialog = this.enableAlertDialog.bind(this)
        this.disableAlertDialog = this.disableAlertDialog.bind(this)

        this.enableConfirmationDialog = this.enableConfirmationDialog.bind(this)
        this.disableConfirmationDialog = this.disableConfirmationDialog.bind(this)
        this.deleteFeedConfirmation = this.deleteFeedConfirmation.bind(this)
    }

    updateState(state) {
        if (this._isMounted) {
            this.setState(state);
        }
    }


    async getFeedPageInfo() {
        if (localStorage.getItem("id") === null && localStorage.getItem("jwt") === null) {
            this.enterDashboardLogin()
        }
    }

    async addEditFeedInfo(publish, id, coverImage, title, content, type) {
        var editFeedPageResult = await addEditFeedInfo(localStorage.getItem("id"), localStorage.getItem("jwt"), publish, id, this.state.imageCheck===true? coverImage:"", title, content, type);
        if (editFeedPageResult.status === APIResult.SUCCESS) {
            debugPrint("Feed Added")
            this.enableAlertDialog("", "Current feed has been edited successfully.", this.backButton)
        }
        else {
            debugPrint("Feed Not Added")
        }
    }

    async deleteFeed() {
        var deleteFeedResult = await deleteFeed(localStorage.getItem("id"), localStorage.getItem("jwt"), this.props.data.feed_id)
        if (deleteFeedResult.status === APIResult.SUCCESS) {
            this.backButton()
        }

    }


    backButton() {
        window.location.href = `/dashboard`;
    }

    updateInput(tag, inputText) {
        if (tag === "title") {
            this.updateState({ title: inputText });
        }
        if (tag === "content") {
            this.updateState({ content: inputText });
        }
    }

    deleteFeedConfirmation() {
        this.setState({ showAlertDialogButton: true });
        this.enableConfirmationDialog("", "Are you sure you to delete current feed? This action is not reversible.")
    }


    updateTitle(event) {
        this.updateState({ title: event.target.value });
    }

    updateContent(event) {
        this.updateState({ content: event.target.value });
    }

    handleChange(event) {
        this.updateState({ emergencyType: event.target.value })
    }

    isEditing() {
        if (this.props.data.feed_id === undefined) {
            this.setState({ editing: false })
        }
    }

    componentDidMount() {
        this._isMounted = true;

        this.getFeedPageInfo()
        this.isEditing()

        this.setState({
            title: this.props.data.feed_title,
            content: this.props.data.feed_content,
            publish: this.props.data.feed_published,
            coverImage: this.props.data.feed_cover_image,
            emergencyType: this.props.data.feed_code,
        });
        if (localStorage.getItem("autoEnablePublish") === "true") {
            this.setState({ publish: 1 })
        }
        
        // if (this.state.emergencyType !== undefined) {
        //     this.setState({ emergencyType: 1 })
        // }

        // if (this.state.title !== undefined) {
        //     this.setState({ title: "" })
        // }

        // if (this.state.content !== undefined) {
        //     this.setState({ content: "" })
        // }


        debugPrint("publish :", this.state.publish)
        debugPrint("emergency type:", this.state.emergencyType)
        debugPrint("title :", this.state.title)
        debugPrint("content:", this.state.content)
        debugPrint("coverImage:", this.state.coverImage)

        debugPrint("data:", this.props.data.feed_content)

    }

    saveContent() {
        var properCoverImage = "";
        if (this.state.coverImage === undefined || this.state.coverImage === "https://siren.the-oaks.my") {
            this.setState({coverImage:""})
        }
        if ((!this.state.coverImage.startsWith("https://") || !this.state.coverImage.startsWith("http://")) && this.state.coverImage.replace(" ", "") !== "") {
            properCoverImage = this.state.coverImage;
        }
        this.addEditFeedInfo(this.switchChanger(this.state.publish), !this.state.editing ? 0 : this.props.data.feed_id, properCoverImage, this.state.title, this.state.content, this.state.emergencyType)
        this.setState({ setDefaultImage: true })
        debugPrint("well", properCoverImage)
    }

    imageCallbackFunction = (imageB64) => {
        this.setState({ coverImage: imageB64 });
        debugPrint("i have passed b64 back to add feed page", imageB64)
    }

    coverImageCallback = (coverImage) => {
        debugPrint(coverImage)
    }

    getImageChangeConfirmation=(confirmation)=>{
        this.setState({imageCheck:confirmation})
        console.log(confirmation,"hello")
    }

    switchChanger(bool) {
        if (bool === true) {
            return 1
        }
        else {
            return 0
        }
    }

    reverseSwitchChanger(int){
        if(int ===1){
            return true;
        }
        else{
            return false
        }

    }
    enableAlertDialog(title, message, action = null) {
        this.updateState({ alertDialogTitle: title, alertDialogMessage: message, showAlertDialog: true, alertDialogAction: action });
    }

    disableAlertDialog() {
        this.setState({ showAlertDialog: false });
    }


    enableConfirmationDialog(title, message, action = null) {
        this.updateState({ confirmationDialogTitle: title, confirmationDialogMessage: message, showConfirmationDialog: true, alertConfirmationDialogAction: action });
    }

    disableConfirmationDialog() {
        this.setState({ showConfirmationDialog: false });
    }
    render() {

        return (
            <div className='feed-container' style={{
                background: "black",
                height: "100vh",
                width: "100vw",
                position: "relative",
                paddingTop: "16px",
                paddingBottom: "120px",
            }}>
                <div style={{

                }}>

                    <div
                        style={{
                            position: "fixed",
                            width: "100%",
                            zIndex: 50,
                            boxShadow: "4px 0px 8px rgba(0, 0, 0, 0.125)",
                            boxSizing: "border-box",
                            backgroundColor: "#262626",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            color: "white",
                            top: 0,
                            left: 0,
                            padding: 10,

                        }}>
                        <IconButton onClick={this.backButton}>
                            <img
                                src={BackIcon}
                                alt="Home"
                                style={{
                                    height: "auto",
                                }} />
                        </IconButton>
                        <text className='page-title'>
                            {!this.state.editing ? " Add New Feed" : "Edit Feed"}
                        </text>
                        {this.state.editing ? <IconButton onClick={() => this.deleteFeedConfirmation()}>
                            <img
                                src={DeleteIcon}
                                alt="Home"
                                style={{
                                    height: "auto",
                                }} />
                        </IconButton> : <IconButton>
                            <img
                                src={DeleteIcon}
                                alt="Home"
                                style={{
                                    height: "auto",
                                    opacity: 0
                                }} />
                        </IconButton>}


                    </div>
                    <div className=".feed-container"
                        style={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "space-evenly"
                        }}>
                        <div style={{
                            display: "flex",
                            justifyItems: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: 10,
                            paddingTop: "50px",
                            background: "white",
                            width: "min-content",
                            height: "100vh",
                            paddingBottom: "80px",
                        }}>
                            <div className='dragAndDrop-container' style={{ margin: "16px" }}>
                                <DragAndDropImageUploader 
                                    parentCallback2={this.getImageChangeConfirmation}
                                    parentCallback={this.imageCallbackFunction} 
                                    defaultImage={this.props.data.feed_cover_image}
                                     />
                            </div>
                            <div>
                                <div style={{
                                    color: "rgba(0, 0, 0, 0.5)",
                                    fontFamily: "lato",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    paddingBottom: 5
                                }}>
                                    Title*
                                </div>

                                <textarea className='feed-container'
                                    onChange={this.updateTitle}
                                    value={this.state.title}
                                    style={{
                                        height: "50px",
                                        resize: "none",
                                        borderRadius: "10px",
                                        background: " #F5F6FA",
                                        border: "none",
                                        padding: 10,
                                        fontFamily: "lato",
                                        fontWeight: "400",
                                        fontSize: "18px",
                                        outline: "none",
                                    }} />
                            </div>
                            <div>
                                <div style={{
                                    color: "rgba(0, 0, 0, 0.5)",
                                    fontFamily: "lato",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    paddingBottom: 5
                                }}>
                                    Content*
                                </div>

                                <textarea
                             
                                    onChange={this.updateContent}
                                    value={this.state.content}
                                    className="feed-container" style={{
                                        height: "250px",
                                        minWidth: "320px",
                                        resize: "none",
                                        borderRadius: "10px",
                                        background: " #F5F6FA",
                                        border: "none",
                                        padding: 10,
                                        fontFamily: "lato",
                                        fontWeight: "400",
                                        fontSize: "18px",
                                        outline: "none",
                                    }} />
                            </div>
                            <div className="publish" style={{
                                color: "rgba(0, 0, 0, 0.5)",
                                fontFamily: "lato",
                                fontSize: "14px",
                                fontWeight: "400"
                            }}>
                                Theme
                            </div>
                            <div>
                                <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }} >

                                    <Select
                                        onChange={this.handleChange}
                                        defaultValue={this.props.data.feed_code}
                                        className="dropdown"
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled">



                                        <MenuItem value={1}>Car Crash </MenuItem><br />
                                        <MenuItem value={2}>Robbery </MenuItem><br />
                                        <MenuItem value={3}>Fire </MenuItem><br />
                                        <MenuItem value={4}>Medical </MenuItem><br />

                                    </Select>
                                </FormControl>
                            </div>
                            <div >
                                <div className="publish" style={{
                                    color: "rgba(0, 0, 0, 0.5)",
                                    fontFamily: "lato",
                                    fontSize: "14px",
                                    fontWeight: "400"
                                }}>
                                    Publish Now?
                                </div>
                                <div className='feed-container' style={{
                                    paddingTop: 10,
                                    paddingBottom: 10
                                }}>

                                </div>
                                <IOSSwitch className='publish'
                                    onChange={e => {
                                        debugPrint(this.switchChanger(e.target.checked));
                                        this.setState({ publish: this.switchChanger(e.target.checked) });

                                    }}
                                    
                                    checked={this.reverseSwitchChanger(this.state.publish)}
                                    

                                />

                            </div>


                            <div className='save-content-button'
                                style={{
                                    position: "fixed",
                                    bottom: 10,

                                }}
                            >
                                <GenericButton
                                    onClick={this.saveContent}
                                    label="Save Content"
                                    fullWidth={true} />
                            </div>
                        </div>
                    </div >
                    <MessageDialog
                        open={this.state.showAlertDialog}
                        onProceed={this.state.alertDialogAction == null
                            ? this.disableAlertDialog
                            : this.state.alertDialogAction}
                        onCancel={this.disableAlertDialog}
                        showCancel={false}
                        title={this.state.alertDialogTitle}
                        message={this.state.alertDialogMessage}
                        transparency={this.state.transparency}
                        showButton={this.state.showAlertDialogButton}
                        showQuery={!this.state.querying ? false : true}
                    />

                    <ConfirmationDialog
                        open={this.state.showConfirmationDialog}
                        onProceed={() => this.deleteFeed()}
                        onCancel={() => this.updateState({ showConfirmationDialog: false })}
                        showCancel={true}
                        title={this.state.confirmationDialogTitle}
                        message={this.state.confirmationDialogMessage}
                        transparency={this.state.transparency}
                        showButton={this.state.showConfirmationDialogButton}
                        showQuery={!this.state.querying ? false : true}
                    />

                </div >
            </div >
        );
    }
}

AddFeed.propTypes = {
    data: PropTypes.object,
    onProceed: PropTypes.func,
}

AddFeed.defaultProps = {
    data: { feed_title: "", feed_content: "", feed_published: 0, feed_cover_image: "", feed_code: 0 },
    onProceed: function () {
        debugPrint("Meow");
    }
};

export default AddFeed;
