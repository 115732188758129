export const DefaultNoImageIcon = "https://ezimart.shop/pics/no_image.png";

export const AppConfig = {
    currency: "RM"
}

export const ProductStatus = {
    DELISTED: 0,
    SELLING: 1,
    SELLING_LIMITED_TIME: 2,
    EXPIRED: 3,
    CLEARED: 4
};

export const InputCode = {
    PAGE_TITLE: "PAGE_TITLE",
    PAGE_DESCRIPTION: "PAGE_DESCRIPTION",
    PAGE_URL: "PAGE_URL",

    FIRST_NAME: "FIRST_NAME",
    LAST_NAME: "LAST_NAME",
    EMAIL: "EMAIL",
    MOBILE_NUMBER: "MOBILE_NUMBER",
    BANK_NAME: "BANK_NAME",
    BANK_ACCOUNT_NUMBER: "BANK_ACCOUNT_NUMBER",
    BANK_HOLDER: "BANK_HOLDER",
    ADDRESS: "ADDRESS",
    CITY: "CITY",
    STATE: "STATE",
    COUNTRY: "COUNTRY",
    POSTCODE: "POSTCODE",
    COMPANY_NAME: "COMPANY_NAME",
    COMPANY_SSM: "COMPANY_SSM",
    PICTURE: "PICTURE",
    SHIP_ADDRESS: "SHIP_ADDRESS",
    SHIP_CITY: "SHIP_CITY",
    SHIP_STATE: "SHIP_STATE",
    SHIP_COUNTRY: "SHIP_COUNTRY",
    SHIP_POSTCODE: "SHIP_POSTCODE",

    VOUCHER_NAME: "VOUCHER_NAME",
    VOUCHER_QUANTITY: "VOUCHER_QUANTITY",
    VOUCHER_CONDITION: "VOUCHER_CONDITION",
    VOUCHER_MINIMUM_SPENDING: "VOUCHER_MINIMUM_SPENDING",
    VOUCHER_MAXIMUM_DISCOUNT: "VOUCHER_MAXIMUM_DISCOUNT",
    VOUCHER_DISCOUNT_RATE: "VOUCHER_DISCOUNT_RATE",
    VOUCHER_START_DATE: "VOUCHER_START_DATE",
    VOUCHER_END_DATE: "VOUCHER_END_DATE",

    PRODUCT_NAME: "PRODUCT_NAME",
    PRODUCT_DESCRIPTION: "PRODUCT_DESCRIPTION",
    PRODUCT_STOCK: "PRODUCT_STOCK",
    PRODUCT_WEIGHT: "PRODUCT_WEIGHT",
    PRODUCT_PRICE: "PRODUCT_PRICE",
    PRODUCT_SHIPPING_COST: "PRODUCT_SHIPPING_COST",
    PRODUCT_SKU: "PRODUCT_SKU",
    PRODUCT_SALE_START: "PRODUCT_SALE_START",
    PRODUCT_SALE_END: "PRODUCT_SALE_END",
    PRODUCT_IMAGE: "PRODUCT_IMAGE"
};

export const AlertDialogMode = {
    DISABLE_DIALOG: 0,
    ACTIVATE_PRODUCT: 1,
    DEACTIVATE_PRODUCT: 2,
    DUPLICATE_PRODUCT: 3,
    DELETE_PRODUCT: 4,
    SAVE_SHOP_GENERIC_SETTINGS: 5,
    SAVE_SHOP_SETTINGS: 6,
    SAVE_SHOP_CONTENT: 7,
    SAVE_PROFILE_DATA: 8,
    ADD_VOUCHER: 9,
    DELETE_VOUCHER: 10,
    LOGOUT: 11,
    SWITCH_SHOP: 12,
    ABOUT_EZIMART: 13,
    FIRST_TIME_SETUP: 14
};

export const DefaultProfile = {
    email: "-",
    mobile: "",
    bank_name: "",
    bank_account_number: "",
    bank_holder: "",
    address: "",
    city: " ",
    state: "",
    country: "",
    poscode: "",
    first_name: "",
    last_name: "",
    company_name: "",
    company_ssm: "",
    ship_address: "",
    ship_city: "Shah Alam",
    ship_state: "Selangor",
    ship_country: "Malaysia",
    ship_poscode: "40150",
    picture: DefaultNoImageIcon
};

export const DefaultShop = {
    active: false,
    adult: 0,
    category: 0,
    category_name: "-",
    content: "Error while loading content",
    shopLogo: "",
    description: "-",
    id: -1,
    name: "-",
    shipping_fee: 0,
    status: 0,
    theme_set: 0,
    url: "-",
    language: "en"
};

export const DefaultPDFData = {
    paid_date: "",
    payout_details_list: [],
    total_buyer_amount: 0,
    total_ez_comm: 0,
    total_paid_amount: 0,
    total_shipping_cost: 0,
    transaction_no: "",
    user_address: "",
    user_bank_account_number: "",
    user_bank_holder: "",
    user_bank_name: "",
    user_city: "",
    user_postcode: "",
    user_country: "",
    user_email: "",
    user_mobile: "",
    user_name: "",
    user_state: ""
};

export const DefaultTransaction = {
    transaction_no: "",
    transaction_amount: 0,
    transaction_time: 0
};

export const DefaultVoucher = {
    active: true,
    id: -1,
    name: "",
    quantity: 0,
    condition: 0,
    minimum_spending: 0,
    maximum_discount: 0,
    discount_rate: 0,
    start_date: 0,
    end_date: 0,
    used: 0
};

export const DefaultProduct = {
    product_sell_mode: 0,
    product_description: "",
    product_image: "",
    product_stock: 0,
    product_name: "",
    product_id: 0,
    product_price: "0",
    product_sale_end: 0,
    product_sale_start: 0,
    product_sku: "",
    product_sold: 0,
    product_variations: [],
    product_weight: "0"
};

export const DefaultTopSellingProduct = {
    productID: 0,
    productInfo: DefaultProduct,
    quantity: 0,
    unitPrice: 0,
    variationID: 0
};

export const DefaultVariation = {
    vid: -1,
    img: "",
    vname: "",
    vquan: 0,
    vprice: 0,
    vsku: "",
    active: 0
};

export const DefaultOrder = {
    id: 0,
    shop_id: 0,
    order_id_for_shop: 0,
    invoice_date: 0,
    url: "",
    status: "paid",
    id_for_display: "",
    purchaser_name: "",
    purchaser_email: "",
    purchaser_phone: "",
    purchaser_address: "",
    purchaser_city: "",
    purchaser_state: "",
    purchaser_postcode: "",
    purchaser_country: "",
    voucher_discount: 0,
    voucher_id: "",
    total_without_voucher: 0,
    total_with_voucher: 0,
    purchased_item: [
        {
            product_id: 0,
            variation_id: 0,
            purchase_quantity: 0,
            unit_price: 0,
            product_name: "",
            product_image: DefaultNoImageIcon
        }
    ],
    paid_date: 0,
    paid_method: "FPX",
    delivery_status: 0,
    tracking_number: "",
    courier: "",
    easy_parcel_airway_pdf: "",
    easy_parcel_order_number: "",
    easy_parcel_number: "",
    easy_parcel_price: "",
    product: [DefaultProduct]
};