import React from 'react';
import { Grid } from '@material-ui/core';
import GenericButton from '../widgets/GenericButton';
import IconTextInput from '../widgets/IconTextInput';
import MessageDialog from '../dialogs/MessageDialog';
import APIResult from '../../configs/APIResult';
import threshold from '../../configs/UIThreshold';
import { Helmet } from "react-helmet";
import Logo from '../../img/icon/siren_logo.png';
import { debugPrint } from '../../utilities/Utilities';

import { passwordLinkValid, resetPassword } from '../../utilities/requests'

class ResetPasswordPage extends React.Component {
    static isAuthenticated(token) {
        if (token)
            return true;
    }

    constructor(props) {
        super(props);

        this.state = {
            mobileMode: false,
            showAlertDialog: false,
            alertDialogTitle: 'Message Dialog Title',
            alertDialogMessage: 'Message Dialog Message',
            alertDialogAction: null,
            buttonDisable: true,
            password: "",
            confirmPassword: "",
            timeOut: false,
            invalidSession: false,
            transparency: true,
            showAlertDialogButton: true,
            querying: false
        };



        this.updateState = this
            .updateState
            .bind(this);

        this.enableAlertDialog = this
            .enableAlertDialog
            .bind(this);

        this.disableAlertDialog = this
            .disableAlertDialog
            .bind(this);

        this.resetPassword = this
            .resetPassword
            .bind(this);

        this.passwordLinkVerification = this
            .passwordLinkVerification
            .bind(this);

        this.encodeBase64 = this
            .encodeBase64
            .bind(this);

        this.enterLogin = this
            .enterLogin
            .bind(this);

        this.enterForgot = this
            .enterForgot
            .bind(this);

        this.updatePassword = this
            .updatePassword
            .bind(this)

        this.updateConfirmPassword = this
            .updateConfirmPassword
            .bind(this)

    }

    enterLogin() {
        this
            .props
            .history
            .push("login");
    }

    enterForgot() {
        this
            .props
            .history
            .push("forgotpassword");
    }

    encodeBase64(password, rounds) {
        for (let i = 0; i < rounds; i++) {
            password = btoa(password)
        }
        return password
    }



    handleResize = e => {
        const windowSize = window.innerWidth;
        this.updateState({
            mobileMode: windowSize < threshold.mobile / 0.75,
            currentWidth: windowSize,
            currentHeight: window.innerHeight
        });
    };


    updateState(state) {
        if (this._isMounted) {
            this.setState(state);
        }
    }



    componentDidMount() {
        this._isMounted = true;
        const windowSize = window.innerWidth;
        this.handleResize();
        this.updateState({
            mobileMode: windowSize < threshold.mobile / 0.75,
            currentWidth: windowSize
        });
        window.addEventListener("resize", this.handleResize);
        this.setState({ querying: true })
        setTimeout(() => {
            this.enableAlertDialog("", "Checking current password reset session...")
        }, 1000);
        this.setState({ invalidSession: true })
        setTimeout(() => { this.passwordLinkVerification() }, 1000);

    }
    enableAlertDialog(title, message, action = null) {
        this.updateState({ alertDialogTitle: title, alertDialogMessage: message, showAlertDialog: true, alertDialogAction: action });
    }

    disableAlertDialog() {
        this.setState({ showAlertDialog: false });
    }

    updatePassword(event) {
        this.updateState({ password: event.target.value });
    }

    updateConfirmPassword(event) {
        this.updateState({ confirmPassword: event.target.value });
    }

    async passwordLinkVerification() {

        this.setState({ querying: true });

        const query = new URLSearchParams(this.props.location.search);
        var token = query.get('t')
        var dateTime = query.get('dt')


        this.setState({ querying: true, invalidSession: true, transparency: false, showAlertDialogButton: false });
        var resetPasswordResult = await passwordLinkValid(dateTime, token);

        if (resetPasswordResult.status === APIResult.SUCCESS) {
            this.setState({ querying: false, invalidSession: false });
            this.disableAlertDialog()
            debugPrint("reset password success")
        }

        else {
            this.updateState({ querying: false });

            if (resetPasswordResult.status === APIResult.RESET_LINK_INVALID) {
                this.setState({ showAlertDialogButton: true });
                this.enableAlertDialog("", "This reset link seems to be invalid. You may submit another Forgot Password again to re-initialize another session.", this.enterForgot);
                debugPrint("invalid session")
            }

            else if (resetPasswordResult.status === APIResult.RESET_LINK_EXPIRED) {
                this.setState({ showAlertDialogButton: true });
                this.enableAlertDialog("", "Current password reset session has been expired, you may submit another Forgot Password again to re-initialize another session.", this.enterForgot);
                debugPrint("link expired")
            }

            else if (resetPasswordResult.status === APIResult.SUCCESS) {
                this.setState({ querying: false, invalidSession: false });
                this.disableAlertDialog()
                debugPrint("success")
            }

            else {
                this.setState({ invalidSession: true });
                this.enableAlertDialog("", `There seems to be an error, please try again`, this.enterForgot);
                debugPrint("reset password failed")
            }

        }
    }


    async resetPassword() {

        const query = new URLSearchParams(this.props.location.search);
        var token = query.get('t')
        var dateTime = query.get('dt')
        this.setState({ transparency: true });
        var encodedPassword = this.encodeBase64(this.state.password, 4)
        debugPrint(encodedPassword)

        if (this.state.password.length < 8) {
            this.setState({ showAlertDialogButton: true });
            this.enableAlertDialog("", "Please make sure password be at least 8 characters long.");
        }
        else if (this.state.password.length !== this.state.confirmPassword.length) {
            this.setState({ showAlertDialogButton: true });
            this.enableAlertDialog("", "Please make sure both password inputs are matching before password reset submission.");
        }
        else if (this.state.password !== this.state.confirmPassword) {
            this.setState({ showAlertDialogButton: true });
            this.enableAlertDialog("", "Please make sure both password inputs are matching before password reset submission.");
        }
        else {

            var resetPasswordResult = await resetPassword(dateTime, token, encodedPassword)

            if (resetPasswordResult.status === APIResult.SUCCESS) {
                this.enableAlertDialog("", "Password has been successfully reset");
                debugPrint("reset password success")

            }
            else if (resetPasswordResult.status === APIResult.RESET_LINK_INVALID) {
                this.enableAlertDialog("", `This reset link seems to be invalid. You may submit another Forgot Password again to re-initialize another session.`);
                debugPrint("reset password invalid")
            }

            else {
                this.enableAlertDialog("", `There seems to be an error, please try again`);
                debugPrint("reset password error")
            }
        }
    }



    render() {

        return (
            <div>
                <div style={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    background: "black"
                }}>
                    <Helmet>
                        <title>Siren Dashboard</title>
                        <meta name="description" content="Siren Dashboard" />

                    </Helmet>

                    <div
                        className=""
                        style={{
                            minHeight: '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'


                        }}>

                        <Grid
                            style={{
                                zIndex: 10,
                                position: "fixed",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexFlow: "column"
                            }}
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center">


                            <Grid
                                item
                                style={{
                                    justifyContent: 'center',
                                    alignItems: "center",
                                    display: 'flex',
                                    flex: "1 1 auto"
                                }}>
                                <img
                                    src={Logo}
                                    alt="logo"
                                    style={{
                                        minWidth: 100,
                                        width: '50%',
                                        maxWidth: 300,
                                        minHeight: 50,
                                        height: "50%",
                                        maxHeight: 150,
                                        objectFit: "scale-down"
                                    }} />
                            </Grid>

                            <div
                                position="relative"
                                style={{
                                    padding: 28,
                                    backgroundColor: 'white',
                                    borderTopLeftRadius: '32px',
                                    borderTopRightRadius: '32px',
                                    maxWidth: "600px",
                                    flex: "0 1 auto",
                                    overflow: "auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    opacity: this.state.invalidSession ? 0 : 1,
                                }}>
                                <div
                                    style={{
                                        fontFamily: "Lato",
                                        fontWeight: 'bold',
                                        fontSize: "25px",
                                        color: 'Black',
                                        textAlign: 'left',
                                        paddingLeft: 10,
                                        paddingRight: 10

                                    }}>Reset Password</div>
                                <div
                                    style={{
                                        fontFamily: "Lato",
                                        fontSize: "18px",
                                        color: 'black',
                                        paddingTop: 36,
                                        paddingBottom: 16,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        textAlign: 'left'
                                    }}>Enter new password to reset your password

                                </div>
                                <Grid
                                    container
                                    alignItems="flex-end">
                                    <IconTextInput
                                        id="password"
                                        label="Password"
                                        type="password"
                                        onChange={this.updatePassword}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="flex-end"

                                    style={{
                                        marginTop: -15
                                    }}>
                                    <IconTextInput
                                        id="confirm_password"
                                        label="Confirm Password"
                                        type="password"
                                        onChange={this.updateConfirmPassword}
                                    />

                                    <Grid
                                        container
                                        justify='flex-end'
                                        alignContent='center'
                                        style={{
                                            alignSelf: 'baseline',
                                            paddingTop: "50px"

                                        }}>
                                        <GenericButton
                                            label="Reset Password"
                                            onClick={this.resetPassword}
                                            fullWidth={true}
                                            enable={this.state.password.length === 0 || this.state.confirmPassword.length === 0 ? false : true}
                                            borderRadius="10px"
                                        />

                                        <Grid xs={6}
                                            justify='center'
                                            alignContent='center'
                                            style={{
                                                textAlign: 'center',
                                                fontFamily: "Lato",
                                                color: "rgba(0,0,0,0.5)",
                                                paddingTop: 30,
                                                paddingBottom: 15
                                            }}>

                                            © Siren
                                        </Grid>

                                        <Grid xs={6}
                                            justify='center'
                                            alignContent='center'
                                            style={{
                                                textAlign: 'center',
                                                fontFamily: "Lato",
                                                color: "rgba(0,0,0,0.5)",
                                                paddingTop: 30,
                                                paddingBottom: 15
                                            }}>
                                            Privacy & terms
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </div>
                </div>
                <MessageDialog
                    open={this.state.showAlertDialog}
                    onProceed={this.state.alertDialogAction == null
                        ? this.disableAlertDialog
                        : this.state.alertDialogAction}
                    onCancel={this.disableAlertDialog}
                    showCancel={false}
                    title={this.state.alertDialogTitle}
                    message={this.state.alertDialogMessage}
                    transparency={this.state.transparency}
                    showButton={this.state.showAlertDialogButton}
                    showQuery={!this.state.querying ? false : true}
                />
            </div >
        );

    }
}
export default ResetPasswordPage;