import React from 'react';
import { Grid } from '@material-ui/core';
import GenericButton from '../widgets/GenericButton';
import IconTextInput from '../widgets/IconTextInput';
import { passwordRequestReset } from '../../utilities/requests'
import APIResult from '../../configs/APIResult';
import threshold from '../../configs/UIThreshold';
import Logo from '../../img/icon/siren_logo.png';
import MessageDialog from '../dialogs/MessageDialog';
import QueryingIndicator from '../widgets/QueryingIndicator';




class ForgotPasswordPage extends React.Component {
    static isAuthenticated(token) {
        if (token)
            return true;
    }
    constructor(props) {
        super(props);

        this.state = {
            mobileMode: false,
            showAlertDialog: false,
            alertDialogTitle: 'Message Dialog Title',
            alertDialogMessage: 'Message Dialog Message',
            alertDialogAction: null,
            transparency: true,
            showAlertDialogButton: true,
            buttonDisabled: true,
            emailMobileNumber: "",
            querying: false,
            defaultEmailMobileValue: ""
        };

        this.updateState = this
            .updateState
            .bind(this);

        this.enableAlertDialog = this
            .enableAlertDialog
            .bind(this);

        this.disableAlertDialog = this
            .disableAlertDialog
            .bind(this);

        this.resetPassword = this
            .resetPassword
            .bind(this);

        this.updateEmailMobileNumber = this
            .updateEmailMobileNumber
            .bind(this);
    }

    handleResize = e => {
        const windowSize = window.innerWidth;
        this.updateState({
            mobileMode: windowSize < threshold.mobile / 0.75,
            currentWidth: windowSize
        });
    };

    updateState(state) {
        if (this._isMounted) {
            this.setState(state);
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const windowSize = window.innerWidth;
        let email = new URLSearchParams(this.props.location.search).get('e');
        this.updateState({
            mobileMode: windowSize < threshold.mobile / 0.75,
            currentWidth: windowSize,
            defaultEmailMobileValue: (email === undefined || email ===null) ? "" : email
        });
        window.addEventListener("resize", this.handleResize);
    }

    enableAlertDialog(title, message, action = null) {
        this.updateState({ alertDialogTitle: title, alertDialogMessage: message, showAlertDialog: true, alertDialogAction: action });
    }

    disableAlertDialog() {
        this.updateState({ showAlertDialog: false });
    }

    updateEmailMobileNumber(event) {
        this.updateState({ defaultEmailMobileValue: event.target.value });

    }

    async resetPassword() {
        var resetPasswordResult = await passwordRequestReset(this.state.defaultEmailMobileValue);

        if (resetPasswordResult.status === APIResult.RESET_PASSWORD_SUCCESS) {
            this.setState({ showAlertDialogButton: false })
            this.enableAlertDialog("", "A password reset e-mail has been sent to your e-mail address.The link in the" +
                " e-mail will expire by 30 minutes from now.",
                this.enterLogin);
        } 
        else if(resetPasswordResult.status === APIResult.RESET_PASSWORD_MAIL_SENT)
        {
            this.setState({ showAlertDialogButton: false })
            this.enableAlertDialog("", "A password reset e-mail has been sent to your e-mail address earlier. Please check your email to reset your password.",
                this.enterLogin);
        }
        else {
            this.enableAlertDialog("", "Failed to reset password, please try again later.");
        }
    }

    render() {

        if (this.state.defaultEmailMobileValue === null || this.state.defaultEmailMobileValue === undefined) {
            this.setState({ defaultEmailMobileValue: "" })
        }

        return (
            <div>
                <div style={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    background: "black"
                }}>
                    {this.state.querying
                        ? <QueryingIndicator
                            style={{
                                position: "absolute",
                                top: 0,
                                height: 5,
                                width: "100vw",
                                zIndex: 5,


                            }} />
                        : null}

                    <div
                        className=""
                        style={{
                            minHeight: '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',

                        }}>

                        <Grid
                            style={{
                                zIndex: 10,
                                position: "fixed",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexFlow: "column"
                            }}
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center">

                            <Grid
                                item
                                style={{
                                    justifyContent: 'center',
                                    alignItems: "center",
                                    display: 'flex',
                                    flex: "1 1 auto"
                                }}>
                                <img
                                    src={Logo}
                                    alt="logo"
                                    style={{
                                        minWidth: 100,
                                        width: '50%',
                                        maxWidth: 300,
                                        minHeight: 50,
                                        height: "50%",
                                        maxHeight: 150,
                                        objectFit: "scale-down"
                                    }} />
                            </Grid>

                            <div
                                position="relative"
                                style={{
                                    padding: 28,
                                    backgroundColor: 'white',
                                    borderTopLeftRadius: '32px',
                                    borderTopRightRadius: '32px',
                                    maxWidth: "600px",
                                    flex: "0 1 auto",
                                    overflow: "auto",
                                    display: "flex",
                                    flexDirection: "column"
                                }}>
                                <div
                                    style={{
                                        fontFamily: "Lato",
                                        fontWeight: 'bold',
                                        fontSize: "25px",
                                        color: 'Black',
                                        textAlign: 'left',
                                        paddingLeft: 10,
                                        paddingRight: 10
                                    }}>Forgot Password</div>
                                <div
                                    style={{
                                        fontFamily: "Lato",
                                        fontSize: "18px",
                                        color: 'black',
                                        paddingTop: 36,
                                        paddingBottom: 16,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        textAlign: 'left'
                                    }}>Enter
                                    the phone number or e-mail address associated with your account and we’ll send
                                    you a link to reset your password.

                                </div>
                                <Grid container alignItems="center">

                                    <IconTextInput
                                        id="email_mobile_number"
                                        label="Email / Mobile Number*"
                                        onChange={this.updateEmailMobileNumber}
                                        defaultValue={this.state.defaultEmailMobileValue}
                                    />
                                </Grid>

                                <Grid
                                    style={{
                                        alignSelf: 'baseline',
                                        padding: 28,
                                        justifyContent: 'center',
                                        alignContent: 'center'
                                    }}
                                    container></Grid>

                                <Grid
                                    container
                                    style={{
                                        alignSelf: 'baseline',
                                        paddingTop: "50px",
                                        justifyContent: 'flex-end',
                                        alignContent: 'center'
                                    }}>

                                    <GenericButton
                                        label="Forgot Password"
                                        onClick={this.resetPassword}
                                        fullWidth={true}
                                        enable={this.state.defaultEmailMobileValue.length === 0 ? false : true}
                                        borderRadius="10px" />

                                    <Grid
                                        xs={6}
                                        item
                                        style={{
                                            textAlign: 'center',
                                            fontFamily: "Lato",
                                            color: "rgba(0,0,0,0.5)",
                                            paddingTop: 30,
                                            paddingBottom: 15,
                                            justifyContent: 'center',
                                            alignContent: 'center'
                                        }}>
                                        © Siren
                                    </Grid>

                                    <Grid
                                        xs={6}
                                        item
                                        style={{
                                            textAlign: 'center',
                                            fontFamily: "Lato",
                                            color: "rgba(0,0,0,0.5)",
                                            paddingTop: 30,
                                            paddingBottom: 15,
                                            justifyContent: 'center',
                                            alignContent: 'center'
                                        }}>
                                        Privacy & terms
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </div>
                </div >
                <MessageDialog
                    open={this.state.showAlertDialog}
                    onProceed={this.state.alertDialogAction == null
                        ? this.disableAlertDialog
                        : this.state.alertDialogAction}
                    onCancel={this.disableAlertDialog}
                    showCancel={false}
                    title={this.state.alertDialogTitle}
                    message={this.state.alertDialogMessage}
                    transparency={this.state.transparency}
                    showButton={this.state.showAlertDialogButton} />
            </div >

        );

    }
}

export default ForgotPasswordPage;