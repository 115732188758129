import React from 'react';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
// import EmergencyCallCard from '../widgets/EmergencyCallCard';
// import EmergencyCallsAnalysis from '../widgets/EmergencyCallsAnalysis';
// import EmergencyCallCard from '../widgets/EmergencyCallCard';
// import TotalEmergencyCallsCard from '../widgets/TotalEmergencyCallsCard';
// import RegisteredUsersCount from '../widgets/RegisteredUsersCount';
// import EmergencyCallsAnalysis from '../widgets/EmergencyCallsAnalysis';
// import FeedCard from '../widgets/FeedCard';
// import KH from './KH';
// import CardFrame from '../widgets/CardFrame';
// import EmergencyCardOverview from '../widgets/EmergencyCardOverview';
// import EmergencyIcons from '../widgets/EmergencyIcons'
// import NotificationAlert from '../widgets/NotificationAlert';
// import AddFeed from './AddFeedPage';
class TestPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    background: "black",
                    width: "100vw",
                    height: "100vh"
                }}>
                <div>
                    {/* <EmergencyCallCard
                        name=" Zong Xi Na "
                        location="Shanghai, China"
                        resolved={false}
                    />

                    <TotalEmergencyCallsCard />
                    <RegisteredUsersCount month1={1} month2={2} month3={3} month4={10} month5={5} month6={6} />
                    <EmergencyCallsAnalysis />
                    <FeedCard />
                    <KH />

                    <CardFrame title="Feeds"><FeedCard /></CardFrame>
                    <CardFrame title="Feeds" large={true}><FeedCard /></CardFrame> 
                     <KH/> */}
                    {/* <EmergencyCardOverview />
                    <EmergencyIcons />
                    <NotificationAlert /> */}
                    {/* <AddFeed /> */}
                    {/* <EmergencyCallsAnalysis />
                    <EmergencyCallCard /> */}
                    <ConfirmationDialog open={true} />
                </div>
            </div>

        );
    }
}
export default TestPage;