import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {debugPrint} from '../../utilities/Utilities';
import QueryingIndicator from '../widgets/QueryingIndicator';
import "./Dialog.css";

class ConfirmationDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.onProceed = this
            .onProceed
            .bind(this);
    }

    onProceed() {
        this
            .props
            .onProceed();
    }
    render() {
        return (

            <div
                style={{
                height: "50wh",
                width: "60vw",
                display: "grid",
                pointerEvents: "none"
            }}>

                <Dialog
                    open={this.props.open}
                    onClose={(event, reason) => {
                    this
                        .props
                        .onCancel();
                }}
                    BackdropProps={{
                    style: this.props.transparency
                        ? {
                            backgroundColor: 'none'
                        }
                        : {
                            backgroundColor: 'transparent'
                        }
                }}
                    PaperProps={{
                    style: {
                        borderRadius: "1em",
                        backgroundColor: "white",
                        padding: 1,
                        minWidth: 350,
                        width: '30%',
                        maxWidth: 600,
                        minHeight: 300,
                        height: "35%",
                        maxHeight: 800,
                        objectFit: "scale-down",
                        boxSizing: "border-box"
                    }
                }}>

                    {this.props.showTitle
                        ? <DialogTitle>
                                <div
                                    style={{
                                    fontFamily: "Lato",
                                    fontSize: 22
                                }}>{this.props.title}</div>
                            </DialogTitle>
                        : null}

                    <DialogContent
                        style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>

                        {this.props.showQuery
                            ? <div>
                                    <QueryingIndicator/>
                                </div>
                            : null}

                        <DialogContentText
                            style={{
                            fontFamily: "Lato",
                            fontSize: "18px",
                            color: "black",
                            padding: 10,
                            textAlign: 'center',
                            alignContent: "center"
                        }}>
                            {this.props.message}
                        </DialogContentText>

                        {this.props.showSecondaryMessage
                            ? <div
                                    style={{
                                    whiteSpace: "pre-wrap",
                                    fontFamily: "Lato",
                                    fontSize: 14,
                                    color: "black",
                                    opacity: 0.75,
                                    padding: 10
                                }}>
                                    {this.props.secondaryMessage}
                                </div>

                            : null}

                    </DialogContent>
                    <div
                        className='dialog-dual-button'>
                        {this.props.showCancel
                            ? <button
                                    onClick={this.props.onCancel}
                                    style={{
                                    borderRadius: "10px",
                                    background: "#EE4139",
                                    width: "50%",
                                    border: "none",
                                    height: "60px",
                                    fontFamily: "lato",
                                    color: "white",
                                    size: "18px",
                                    fontWeight: 700
                                }}>NO</button>
                            : null}
                        {this.props.showButton
                            ? <button
                                    onClick={this.onProceed}
                                    style={{
                                    borderRadius: "10px",
                                    background: "white",
                                    border: 'solid 2px red',
                                    width: "50%",
                                    height: "60px",
                                    fontFamily: "lato",
                                    color: "#EE4139",
                                    size: "18px",
                                    fontWeight: 700
                                }}>
                                    YES</button>
                            : null}

                    </div>
                </Dialog >

            </div >

        );
    }

}

ConfirmationDialog.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    secondaryMessage: PropTypes.string,
    showSecondaryMessage: PropTypes.bool,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onProceed: PropTypes.func,
    borderRadius: PropTypes.string,
    transparency: PropTypes.bool,
    showButton: PropTypes.bool,
    showQuery: PropTypes.bool,
    showTitle: PropTypes.bool,
    showCancel: PropTypes.bool

};

ConfirmationDialog.defaultProps = {
    title: 'Message Dialog Title',
    message: 'Message Dialog Message',
    secondaryMessage: "Message Dialog Secondary Message",
    showSecondaryMessage: false,
    transparency: true,
    showTitle: true,
    showButton: true,
    okText: 'YES',
    cancelText: 'NO',
    borderRadius: '10px',
    showQuery: false,
    open: false,
    showCancel: true,
    onCancel: function () {
        debugPrint("Message Dialog : Cancel Button Pressed");
    },
    onProceed: function () {
        debugPrint("Message Dialog : OK Button Pressed");
    }
};

export default ConfirmationDialog;