import './App.css';
import {Switch, Route} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
import ForgotPasswordPage from './components/pages/ForgotPasswordPage'
import ResetPasswordPage from './components/pages/ResetPasswordPage';
import TestPage from './components/pages/TestPage';
import EmergencyPage from './components/pages/EmergencyPage';
import DashboardPage from './components/pages/DashboardPage';
import FeedsPage from './components/pages/FeedsPage';
import HomePage from './components/pages/HomePage';
import SettingsPage from './components/pages/SettingsPage';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import KH from './components/pages/KH';
import DefaultPage from './components/pages/DefaultPage';
import AddFeedPage from './components/pages/AddFeedPage';
import FirebasePage from './components/pages/FirebasePage';
import CustomizedSwitches from './components/references/toggleSwitch';
import "./components/common.css";
import packageJson from "../package.json";

function App() {

    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route path="/dashboardlogin" exact component={(LoginPage)}/>
                    <Route path="/forgotpassword" exact component={(ForgotPasswordPage)}/>
                    <Route path="/resetpassword" exact component={(ResetPasswordPage)}/>
                    <Route path="/dashboard" exact component={(DashboardPage)}/>
                    <Route path="/test" exact component={(TestPage)}/>
                    <Route path="/emergency" exact component={(EmergencyPage)}/>
                    <Route path="/feeds" exact component={(FeedsPage)}/>
                    <Route path="/home" exact component={(HomePage)}/>
                    <Route path="/settings" exact component={(SettingsPage)}/>
                    <Route path="/kh" component={(KH)}/>
                    <Route path="/addfeed" component={(AddFeedPage)}/>
                    <Route path="/toggleswitch" component={(CustomizedSwitches)}/>
                    <Route path="/firebase" component={(FirebasePage)}/>
                    <Route path="/" component={(DefaultPage)}/>
                </Switch>
            </BrowserRouter >

            <div className='version' >v{packageJson.version}</div>
        </div>
    );
}

export default App;