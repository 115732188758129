import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ConfigUIColors from '../../configs/UIColors';
import { IconButton } from '@material-ui/core';
import { AddAPhotoOutlined } from '@material-ui/icons';
import Dropzone from 'react-dropzone';
import { DefaultNoImageIcon } from '../../configs/GenericCode';
import { debugPrint } from '../../utilities/Utilities';
import UploadImageIcon from "../../img/icon/ImageUpload.svg"
import "./DragAndDropImageUploader.css";

class DragAndDropImageUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultImage: "",
            imageB64: "",
            prepareToUploadImage: true,
            showAlertDialog: false,
            currentWidth: 0,
            currentHeight: 0,
            mobileMode: false,
            alertDialogTitle: 'Message Dialog Title',
            alertDialogMessage: 'Message Dialog Message',
            imageCheck:false
        }
       
    }

    enableAlertDialog(title, message) {
        this.setState({ alertDialogTitle: title, alertDialogMessage: message, showAlertDialog: true });
    }

    disableAlertDialog() {
        this.setState({ showAlertDialog: false });
    }

    componentDidMount() {
        var temp = ""
        if (this.props.defaultImage !== undefined) {
            temp = this.props.defaultImage
        }
        this.setState({ imageB64: temp, prepareToUploadImage: this.props.prepareToUploadImage })
        debugPrint("passed in defaultimage is ", temp);
        this.props.parentCallback2(this.state.imageCheck)
        
    }

    addImage(inputFile) {
        this.getBase64FromImage(inputFile[0])
        this.setState({imageCheck:true})
        this.props.parentCallback2(this.state.imageCheck)
        debugPrint(inputFile[0])
    }

    saveImage(imageString) {
    
        this.setState({ imageB64:imageString});
        this.props.parentCallback(imageString)

    }

    getBase64FromImage(file) {
        let this2 = this;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            this2.saveImage(reader.result);
        };
        reader.onerror = function (error) {
            debugPrint('Error: ', error);
        };
    }


    render() {
        if (this.state.prepareToUploadImage) {
            return (
                <div
                    className="dragAndDrop-container"
                    style={{
                        borderRadius: 16,
                        backgroundColor: "#EE413933",
                        height: "125px",
                        border: " 2px dashed rgba(238, 65, 57, 0.75)",
                        justifyContent: "center",
                        alignContent: "center",
                        position: "relative"
                    }}>
                    <Dropzone
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                        onDrop={acceptedFiles => this.addImage(acceptedFiles)}
                        className="noselect">
                        {({ getRootProps, getInputProps }) => (
                            <section
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center"
                                }}>
                                <div
                                    {...getRootProps()}
                                    style={{
                                        display: "flex",
                                        justifySelf: "center",
                                        alignSelf: "center",
                                        height: "100%"
                                    }}>
                                    <input {...getInputProps()} />


                                    {this.state.imageB64 === ""
                                        ? <Grid
                                            style={{
                                                alignSelf: "center",
                                                padding: 8
                                            }}
                                            container
                                            direction="column">
                                            <Grid
                                                item
                                                style={{
                                                    justifyContent: "center",
                                                    alignSelf: "center",
                                                    width: "100%",
                                                    textAlign: "center"
                                                }}>
                                                {< img src={UploadImageIcon}
                                                    style={{ padding: 10 }} alt="icon" />
                                                }</Grid>
                                            <Grid
                                                item
                                                className="noselect"
                                                style={{
                                                    fontSize: "14px",
                                                    color: "rgba(0, 0, 0, 0.75)",
                                                    fontWeight: 500,
                                                    textAlign: "center"
                                                }}>
                                                {"Drag your image here or click to upload cover image"}</Grid>
                                        </Grid>
                                        : <img
                                            alt=""
                                            src={this.state.imageB64}
                                            style={{
                                                objectFit: "contain",
                                                borderRadius: "8px",
                                                padding: 5
                                            }} />}
                                </div>
                            </section>
                        )}
                    </Dropzone>

                </div >
            );
        } else {
            return (
                <div
                    style={{
                        borderRadius: 16,
                        backgroundColor: "#D2D9DB",
                        position: "relative",
                        height: this.getSize(),
                        borderWidth: 3,
                        borderStyle: "solid",
                        borderColor: ConfigUIColors.primaryShadow,
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center"
                    }}>
                    <div
                        style={{
                            padding: 8,
                            width: "100%",
                            display: "flex"
                        }}></div>
                    <IconButton
                        onClick={this.prepareToUploadImage}
                        style={{
                            width: 50,
                            height: 50,
                            position: "absolute",
                            top: 8,
                            right: 8,
                            zIndex: 10
                        }}>
                        <AddAPhotoOutlined /></IconButton>
                </div>
            );
        }
    }
}

DragAndDropImageUploader.propTypes = {

    imagePath: PropTypes.string,
    prepareToUploadImage: PropTypes.bool,
    mode: PropTypes.string,
    size: PropTypes.string,
    aspectRatioCheck: PropTypes.number,
    onUploadComplete: PropTypes.func
};

DragAndDropImageUploader.defaultProps = {
    imagePath: DefaultNoImageIcon,
    prepareToUploadImage: true,
    mode: "p",
    size: "medium",
    aspectRatioCheck: 0,
    onUploadComplete: function () {
        debugPrint(`DragAndDropImageUploader id=${this.id} pressed`);
    }
};

export default DragAndDropImageUploader;