import React from 'react';
import "./NavigationButton.css";
import PropTypes from 'prop-types';
import ConfigUIColors from '../../configs/UIColors';

class NavigationButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.getColor = this
            .getColor
            .bind(this);
    }

    getColor() {
        if (this.props.disable) 
            return ConfigUIColors.grey;
        if (this.props.secondary) 
            return ConfigUIColors.primaryDark;
        return ConfigUIColors.primary;
    }

    render() {
        return (<img
            alt="btn"
            onClick={this.props.onClick}
            src={this.props.image}
            className="bottom-navigation-btn"/>);
    }
}

function defaultFunc() {
    console.log("NavigationButton pressed");
}

NavigationButton.propTypes = {
    enable: PropTypes.bool,
    label: PropTypes.string,
    image: PropTypes.string,
    onClick: PropTypes.func
};

NavigationButton.defaultProps = {
    enable: false,
    label: "NavigationButton",
    image: "",
    onClick: defaultFunc
};

export default NavigationButton;
