import PropTypes from 'prop-types';
import React from 'react';
import { DEFAULT_EMERGENCY_CALLS_COUNT } from '../../configs/Default';
import { isValidMonth } from '../../utilities/Utilities';

class RegisteredUsersCount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}

        this.getBarsHeight = this
            .getBarsHeight
            .bind(this);
        this.isNaNChecker = this
            .isNaNChecker
            .bind(this)
        this.zeroToOne = this.zeroToOne.bind(this)

        this.allMonths = this.state.allMonths
        this.maxHeight = 100;
        this.minHeight = 10;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    // 1. Double check if data is intact "m-01" "m-02"
    // 2. Find the largest number across all months
    // 3. Keeps values at their own place, this.state for things to be shown on
    // screen, this.xxx for constant
    // 4. componentDidMount -> do 1, 2, start to process number

    isNaNChecker(rate) {

        if (!isFinite(rate) || isNaN(rate))
            rate = 0
        return rate
    }

    zeroToOne(number) {
        if (number === 0)
            number = 1
        return number
    }

    getBarsHeight(count) {
        var finalHeight = 0;
        if (count !== undefined && count !== null && !isNaN(count) && count > 0) {
            finalHeight = count / this.props.highest * this.maxHeight;
        }
        if (isNaN(finalHeight)) {
            finalHeight = 0;
        }

        return Math.max(Math.min(this.maxHeight, finalHeight), this.minHeight);
    }

    updateState(state) {
        if (this._isMounted) {
            this.setState(state);
        }
    }

    increasing() {
        return this.props.data["m-0"] >= this.props.data["m-1"];
    }

    getBarsColor(count) {
        // this.props.highest === this.props.data[eachMonth] ? "#00EAB6" : "rgba(255, 255, 255, 0.35)"
        var finalBackgroundColor = "#00EAB6";
        if (this.props.colorCode === "green") {
            if (this.props.highest === count) {
                finalBackgroundColor = "#00EAB6";
            }
            else {
                finalBackgroundColor = "rgba(255, 255, 255, 0.35)";
            }
        }

        if (this.props.colorCode === "red") {
            if (this.props.highest === count) {
                finalBackgroundColor = "red";
            }
            else if (this.props.lowest === count) {
                finalBackgroundColor = "#00EAB6";
            }
            else {
                finalBackgroundColor = "rgba(255, 255, 255, 0.35)";
            }
        }

        return finalBackgroundColor;
    }

    render() {
        return (
            <div style={{ userSelect: "none " }}>
                <div
                    style={{
                        color: "rgba(255, 255, 255, 0.5)",
                        fontWeight: "normal",
                        fontSize: "12px"
                    }}>
                    Last 6 Months
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        gap: 2
                    }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column"
                        }}>

                        <div
                            style={{
                                color: "white",
                                fontFamily: "lato",
                                fontWeight: "bold",
                                fontSize: "36px"
                            }}>
                            {this.props.data["total"]}
                        </div>
                        <div
                            style={{
                                borderRadius: "12px",
                                backgroundColor: this.props.isRegUserCount ?
                                    `${this.increasing() ? "rgba(0, 234, 182, 0.25) " : "rgba(238, 65, 57, 0.25)"}` :
                                    `${this.increasing() ? "rgba(238, 65, 57, 0.25)" : "rgba(0, 234, 182, 0.25) "}`,
                                fontSize: "10px",
                                height: "auto",
                                paddingRight: 8,
                                paddingLeft: 8,
                                paddingTop: 4,
                                paddingBottom: 4,
                                width: "min-content",
                                color: this.props.isRegUserCount ?
                                    `${this.increasing() ? "#00EAB6" : "red"}` :
                                    `${this.increasing() ? "red" : "#00EAB6"}`,
                                border: this.props.isRegUserCount ?
                                    `${this.increasing() ? "1px solid #00EAB6" : "1px solid red"}` :
                                    `${this.increasing() ? "1px solid red" : "1px solid #00EAB6"}`,
                                justifyContent: "flex-start"
                            }}>
                            {this.props.data["m-0"] >= this.props.data["m-1"] ? "+" : "-"}{parseInt(this.isNaNChecker(this.zeroToOne(this.props.data["m-0"]) / this.zeroToOne(this.props.data["m-1"]) * 100))}%
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                            gap: "0.25em"
                        }}>
                        {Object
                            .keys(this.props.data)
                            .sort()
                            .reverse()
                            .map((eachMonth) => {
                                if (isValidMonth(eachMonth)) {
                                    return (
                                        <div
                                            key={"bars" + eachMonth}
                                            style={{
                                                maxHeight: this.maxHeight,
                                                height: this.getBarsHeight(this.props.data[eachMonth]),
                                                width: 26,
                                                backgroundColor: this.getBarsColor(this.props.data[eachMonth]),
                                                borderRadius: "8px"
                                            }}></div>
                                    );
                                }
                            })}
                    </div>

                </div>
            </div >

        );
    }
}

RegisteredUsersCount.propTypes = {
    data: PropTypes.object,
    highest: PropTypes.number,
    colorCode: PropTypes.string,
    isRegUserCount: PropTypes.bool,
    lowest: PropTypes.number

};

RegisteredUsersCount.defaultProps = {
    data: DEFAULT_EMERGENCY_CALLS_COUNT,
    highest: 0,
    colorCode: "green",
    isRegUserCount: true,
    lowest: 0

};
export default RegisteredUsersCount;