import React from 'react';
import PropTypes from 'prop-types';
import "./CardFrame.css";
import ArrowIcon from "../../img/icon/moreArrow.svg"
class CardFrame extends React.Component {
    render() {
        return (
            <div
                className={`card-frame-parent ${this.props.large
                    ? "large-card"
                    : ""}`}
            // style={{ userSelect: "none " }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                    <text className='card-frame-title'>{this.props.title}</text>
                    {this.props.icon !== ""
                        ? <div onClick={this.props.enterAddFeeds}>
                            <img
                                src={this.props.icon}
                                style={{
                                    cursor: "pointer"
                                }}
                                alt="icon" />
                        </div>
                        : null}
                </div>
                <div className='card-frame-content'>{this.props.children}</div>
                {this.props.showButton
                    ? <div
                        style={{
                            display: "flex",
                            justifyContent: 'flex-end'
                        }}>
                        <div onClick={this.props.enterPage}
                            className='more-btn'>
                            <div
                                style={{
                                    color: "white",
                                    fontFamily: "Lato",
                                    fontWeight: 'bold',
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                    marginLeft: "0.5em",
                                    marginRight: "0.5em"
                                }}>
                                More
                            </div>
                            <div style={{}}>
                                <img src={ArrowIcon} alt="icon" />
                            </div>
                        </div>
                    </div>
                    : null
                }
            </div>
        );
    }
}

CardFrame.propTypes = {
    title: PropTypes.string,
    large: PropTypes.bool,
    icon: PropTypes.string,
    showButton: PropTypes.bool,
    enterAddFeeds: PropTypes.func,
    enterPage: PropTypes.func
};

CardFrame.defaultProps = {
    title: "",
    large: false,
    icon: "",
    showButton: false,
    enterAddFeeds: function () {
    },
    enterPage: function () {
    }
};

export default CardFrame;
