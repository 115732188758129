import React from 'react';
import EmergencyCallCard from '../widgets/EmergencyCallCard';
import Exclamation from "../../img/icon/ExclamationMark.svg"
import GreenExclamation from "../../img/icon/green_exclamation.svg"
import CardFrame from '../widgets/CardFrame';
import {emergencyPageInfo} from '../../utilities/requests';
import APIResult from '../../configs/APIResult';
import QueryingIndicator from '../widgets/QueryingIndicator';
import "./EmergencyPage.css";
import PageNavigation from "../widgets/PageNavigation"
import firestoreDB from '../../utilities/Firebase';
import {doc, onSnapshot} from "firebase/firestore";
import {getEmergencyIcon, showDashedValue} from '../../utilities/Utilities';
import EmergencyCardOverview from '../widgets/EmergencyCardOverview';
import {DEFAULT_EMERGENCY_VICTIM_INFO} from '../../configs/Default';
import {debugPrint} from '../../utilities/Utilities';

const ONGOING = 0;
const RESOLVED = 1;

class EmergencyPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            dataToOverview: 2,
            showEmergencyCardOverview: false,
            currentEmergencyOverview: DEFAULT_EMERGENCY_VICTIM_INFO,
            loading: true,
            onGoingEmergencies: [],
            resolvedEmergencies: [],
            totalOnGoingPages: 0,
            totalResolvedPages: 0,
            currentResolvedPage: 0
        }
        this.getOnGoingEmergencyPageInfo = this
            .getOnGoingEmergencyPageInfo
            .bind(this)
        this.getResolvedEmergencyPageInfo = this
            .getResolvedEmergencyPageInfo
            .bind(this)

        this.enterEmergencyOverview = this
            .enterEmergencyOverview
            .bind(this);
        this.UpdateData = this
            .UpdateData
            .bind(this)
        this.PageNavigation = this
            .navigatePages
            .bind(this)
        this.getEmergencyType = this
            .getEmergencyType
            .bind(this)

        this.trackFirebaseInfo = this
            .trackFirebaseInfo
            .bind(this);
        this.currentPage = 1
        this.onGoingPages = 0
        this.cardLimit = 6
        this.itemPerPage = 6

    }
    componentDidMount() {

        this.getOnGoingEmergencyPageInfo()
        this.getResolvedEmergencyPageInfo()
        // this.UpdateData()
        // debugPrint(Math.ceil(Math.max(this.state.totalResolvedPages, 1) /
        // this.itemPerPage));

    }

    navigatePages(page) {
        this.currentPage = page
        this.UpdateData()
    }

    enterEmergencyOverview(emergencyInfo) {
        this.setState({
            showEmergencyCardOverview: !this.state.showEmergencyCardOverview,
            currentEmergencyOverview: emergencyInfo
        })
    }

    async trackFirebaseInfo(channelID, userID, type = ONGOING) {

        onSnapshot(doc(firestoreDB, "emergency", channelID, "location", userID), (doc) => {

            var allEmergency = type === ONGOING
                ? this.state.onGoingEmergencies
                : this.state.resolvedEmergencies;
            var updatedEmergency = [];
            allEmergency.forEach(eachEmergency => {
                if (eachEmergency.firebase_channel_id === channelID) {
                    if (doc.data() !== undefined) {
                        eachEmergency.longitude = doc
                            .data()
                            .long;
                        eachEmergency.latitude = doc
                            .data()
                            .lat;
                    }
                }

                updatedEmergency.push(eachEmergency);
            });
            if (type === ONGOING) {
                this.setState({onGoingEmergencies: updatedEmergency});
            } else if (type === RESOLVED) {
                this.setState({resolvedEmergencies: updatedEmergency});
            }
        });
    }

    async getEmergencyType(channelID, type = ONGOING) {

        onSnapshot(doc(firestoreDB, "emergency", channelID, "emergency_info", "host"), (doc) => {

            var allEmergency = type === ONGOING
                ? this.state.onGoingEmergencies
                : this.state.resolvedEmergencies;
            var updatedEmergency = [];
            allEmergency.forEach(eachEmergency => {
                if (eachEmergency.firebase_channel_id === channelID) {
                    if (doc.data() !== undefined) {

                        eachEmergency.emergencyType = doc
                            .data()
                            .emergency_type;
                    }
                }
                updatedEmergency.push(eachEmergency);

            });
            if (type === ONGOING) {
                this.setState({onGoingEmergencies: updatedEmergency});
            } else if (type === RESOLVED) {
                this.setState({resolvedEmergencies: updatedEmergency});
            }

        });
    }

    async UpdateData() {
        var emergencyPageResult = await emergencyPageInfo(localStorage.getItem("id"), localStorage.getItem("jwt"), 0, this.cardLimit, this.currentPage + 1)

        if (emergencyPageResult.status === APIResult.SUCCESS) {
        }
    }

    async getOnGoingEmergencyPageInfo() {

        if (localStorage.getItem("id") === null && localStorage.getItem("jwt") === null) {
            this.enterDashboardLogin()
        }
        var emergencyPageResult = await emergencyPageInfo(localStorage.getItem("id"), localStorage.getItem("jwt"), 1, this.cardLimit, this.currentPage);
        if (emergencyPageResult.status === APIResult.SUCCESS) {
            var updatedEmergency = [];
            emergencyPageResult
                .emergency
                .forEach(eachEmergency => {
                    eachEmergency.longitude = 0;
                    eachEmergency.latitude = 0;
                    updatedEmergency.push(eachEmergency);
                });

            this.setState({
                totalOnGoingPages: emergencyPageResult.total,
                onGoingEmergencies: updatedEmergency
            }, () => {
                emergencyPageResult
                    .emergency
                    .forEach(eachEmergency => {
                        this.trackFirebaseInfo(eachEmergency.firebase_channel_id, eachEmergency.user_id, ONGOING);
                        this.getEmergencyType(eachEmergency.firebase_channel_id, ONGOING)
                    });
            })
            // debugPrint(this.onGoingPages)
        } else if (emergencyPageResult.status === APIResult.INVALID_SESSION) {
            this.enterDashboardLogin()

        } else {
            debugPrint("Emergency API couldnt work")
        }

    }

    async getResolvedEmergencyPageInfo() {
        this.setState({loading: true})
        if (localStorage.getItem("id") === null && localStorage.getItem("jwt") === null) {
            this.enterDashboardLogin()
        }

        var emergencyPageResult = await emergencyPageInfo(localStorage.getItem("id"), localStorage.getItem("jwt"), 0, this.cardLimit, this.state.currentResolvedPage);
        if (emergencyPageResult.status === APIResult.SUCCESS) {

            // var finalEmergencyInfo = [];
            var updatedEmergency = [];
            emergencyPageResult
                .emergency
                .forEach(eachEmergency => {
                    eachEmergency.longitude = 0;
                    eachEmergency.latitude = 0;
                    updatedEmergency.push(eachEmergency);

                });

            this.setState({
                resolvedEmergencies: updatedEmergency

            }, () => {
                emergencyPageResult
                    .emergency
                    .forEach(eachEmergency => {

                        this.trackFirebaseInfo(eachEmergency.firebase_channel_id, eachEmergency.user_id, RESOLVED);
                        this.getEmergencyType(eachEmergency.firebase_channel_id, RESOLVED)
                    });
            })
            this.setState({loading: false, totalResolvedPages: emergencyPageResult.total})
            console.log(this.state.totalResolvedPages, "lets see")

        } else if (emergencyPageResult.status === APIResult.INVALID_SESSION) {
            this.enterDashboardLogin()
            this.setState({loading: false})
        } else {
            debugPrint("Emergency API couldnt work")
            this.setState({loading: false})
        }

    }

    render() {
        const {dataToOverview} = this.state
        return (
            <div
                className=""
                style={{
                width: "100%",
                height: this.state.loading
                    ? "100vh"
                    : " 100%",
                paddingBottom: "200px",
                marginTop: 16
            }}>
                <div
                    style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10
                }}>

                    {this.state.showEmergencyCardOverview
                        ? <div>
                                <div
                                    style={{
                                    position: "relative",
                                    width: "100vw",
                                    height: "100vh",
                                    zIndex: 35
                                }}>

                                    <div
                                        style={{
                                        position: "fixed",
                                        width: "100vw",
                                        height: "100vh",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        top: 0,
                                        left: 0
                                    }}
                                        className="emergencyCardOverview-layout">
                                        <EmergencyCardOverview
                                            pageType={dataToOverview}
                                            onProceed={this.enterEmergencyOverview}
                                            info={this.state.currentEmergencyOverview}/>
                                    </div>
                                </div>
                            </div>
                        : null
}

                    <div className='home-parent'>
                        <CardFrame title="On-Going" icon={Exclamation}>
                            {this
                                .state
                                .onGoingEmergencies
                                .map((emergencyInfo, index) => {
                                    console.log(emergencyInfo);
                                    return <div
                                        key={index}
                                        onClick={() => this.enterEmergencyOverview(emergencyInfo)}
                                        style={{
                                        marginBottom: 10,
                                        marginTop: 10
                                    }}>
                                        <EmergencyCallCard
                                            key={index}
                                            id={index}
                                            emergencyInfo={emergencyInfo}/>
                                    </div>
                                })}

                            <div
                                style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "2em"
                            }}>
                                <PageNavigation
                                    onPageChange={this.UpdateData}
                                    total={(Math.ceil(Math.max(1, this.state.totalOnGoingPages) / this.itemPerPage))}/>
                            </div>
                        </CardFrame>
                        <CardFrame title="Resolved" icon={GreenExclamation}>
                            {this
                                .state
                                .resolvedEmergencies
                                .map((emergencyInfo, index, arr) => {

                                    return <div
                                        key={index}
                                        onClick={() => this.enterEmergencyOverview(emergencyInfo)}
                                        style={{
                                        marginBottom: 10,
                                        marginTop: 10
                                    }}>
                                        <EmergencyCallCard
                                            key={index}
                                            id={index}
                                            emergencyInfo={emergencyInfo}/>
                                    </div>
                                })}

                            <div
                                style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "2em"
                            }}>
                                <PageNavigation
                                    currentPage={this.state.currentResolvedPage + 1}
                                    total={Math.ceil(Math.max(this.state.totalResolvedPages, 1) / this.itemPerPage)}
                                    onPageChange={(e) => {
                                    this.setState({
                                        currentResolvedPage: e - 1
                                    }, () => {
                                        this.getResolvedEmergencyPageInfo()
                                    });
                                }}/></div>
                        </CardFrame>
                    </div>
                </div>
                {this.state.loading
                    ? <div className="loading-bg"/>
                    : null
}
                {this.state.loading
                    ? <div
                            id='ppwwwoopp'
                            style={{
                            position: "fixed",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100vw",
                            height: "100vh",
                            zIndex: 2000,
                            left: 0,
                            top: 0
                        }}>
                            <QueryingIndicator/>
                        </div>
                    : null
}
            </div>
        );
    }
}
EmergencyPage.propTypes = {}

EmergencyPage.defaultProps = {};

export default EmergencyPage;
