import React from 'react';
import "./HomePage.css";
import EmergencyCallCard from '../widgets/EmergencyCallCard';
import PropTypes from 'prop-types';
import TotalEmergencyCallsCard from '../widgets/TotalEmergencyCallsCard';
import Exclamation from "../../img/icon/ExclamationWhite.svg";
import RegisteredUsersCount from '../widgets/RegisteredUsersCount';
import FeedCard from '../widgets/FeedCard';
import FeedsIcon from "../../img/icon/FeedsWhite.svg"
import CardFrame from '../widgets/CardFrame';
import { homepageInfo } from '../../utilities/requests';
import APIResult from '../../configs/APIResult';
import { DEFAULT_EMERGENCY_CALLS_COUNT, DEFAULT_EMERGENCY_VICTIM_INFO } from '../../configs/Default';
import EmergencyCardOverview from '../widgets/EmergencyCardOverview'
import firestoreDB from '../../utilities/Firebase';
import { doc, onSnapshot } from "firebase/firestore";
import { calcMaxNum, calcMinNum, getEmergencyIcon, isValidMonth, showDashedValue, capitalizeFirstLetter, getEmergencyIconFromName } from '../../utilities/Utilities';
import QueryingIndicator from '../widgets/QueryingIndicator';

class HomePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataToOverview: 1,
            emergencyType: 0,
            loading: true,
            showEmergencyCardOverview: false,
            currentEmergencyOverview: DEFAULT_EMERGENCY_VICTIM_INFO,
            registeredUsersCount: DEFAULT_EMERGENCY_CALLS_COUNT,
            emergencyCallCount: DEFAULT_EMERGENCY_CALLS_COUNT,
            latestEmergency: [],
            feedCards: [],
            serviceNumberCollection: {},
            policeNumber: "",
            fireBrigadeNumber: "",
            carTowNumber: "",
            totalEmergency: {},
            emergencyCards: [{
                id: 1,
                title: "Total Calls",
                name: "Medical",
                totalCalls: 153,
                monthlyCalls: 43
            },
            {
                id: 2,
                title: "Total Calls",
                name: "Crime",
                totalCalls: 65,
                monthlyCalls: 15

            },
            {
                id: 3,
                title: "Total Calls",
                name: "Crime",
                totalCalls: 5,
                monthlyCalls: 1

            }

            ],

        };
        this.getHomepageInfo = this.getHomepageInfo.bind(this);
        this.enterEmergencyOverview = this.enterEmergencyOverview.bind(this);
        this.enterAddFeedsPage = this.enterAddFeedsPage.bind(this);
        this.trackFirebaseInfo = this.trackFirebaseInfo.bind(this);
    }


    componentDidMount() {
        this.getHomepageInfo()
    }

    enterEmergencyOverview(emergencyInfo) {
        this.setState({ showEmergencyCardOverview: !this.state.showEmergencyCardOverview, currentEmergencyOverview: emergencyInfo })
    }

    enterAddFeedsPage() {
        window.location.href = `/addfeed`;
    }

    enterDashboardLogin() {
        window.location.href = `/dashboardlogin`
    }

    async trackFirebaseInfo(channelID, userID) {

        onSnapshot(doc(firestoreDB, "emergency", channelID, "location", userID.toString()), (doc) => {

            var allEmergency = this.state.latestEmergency
            var updatedEmergency = [];
            allEmergency.forEach(eachEmergency => {
                if (eachEmergency.channel_id === channelID) {
                    if (doc.data() !== undefined) {

                        eachEmergency.longitude = doc.data().long;
                        eachEmergency.latitude = doc.data().lat;
                    }
                }
                updatedEmergency.push(eachEmergency);
            });
            this.setState({ latestEmergency: updatedEmergency });
        });
    }

    async getEmergencyType(channelID) {

        onSnapshot(doc(firestoreDB, "emergency", channelID, "emergency_info", "host"), (doc) => {

            var allEmergency = this.state.latestEmergency
            var updatedEmergency = [];
            allEmergency.forEach(eachEmergency => {
                if (eachEmergency.channel_id === channelID) {
                    if (doc.data() !== undefined) {

                        eachEmergency.emergencyType = doc.data().emergency_type;
                    }
                }
                updatedEmergency.push(eachEmergency);

            });

            this.setState({ latestEmergency: updatedEmergency });
        });
    }
    getAllMonths(data) {
        var allMonths = [];
        Object
            .keys(DEFAULT_EMERGENCY_CALLS_COUNT)
            .forEach(eachKey => {
                if (isValidMonth(eachKey)) {
                    let currentVal = data[eachKey];
                    if (currentVal !== undefined && currentVal != null && !isNaN(currentVal)) {
                        allMonths.push(currentVal);
                    } else {
                        allMonths.push(0);
                    }
                }
            });
        return allMonths;
    }


    async getHomepageInfo() {
        this.setState({ loading: true })
        if (localStorage.getItem("id") === null && localStorage.getItem("jwt") === null) {
            this.enterDashboardLogin()
        }

        var homepageResult = await homepageInfo(localStorage.getItem("id"), localStorage.getItem("jwt"));
        if (homepageResult.status === APIResult.SUCCESS) {

            var updatedEmergency = [];

            homepageResult.latest_emergency.forEach(eachEmergency => {
                eachEmergency.longitude = 0;
                eachEmergency.latitude = 0;
                eachEmergency.emergencyType = 0
                updatedEmergency.push(eachEmergency);
                this.setState({
                    latestEmergency: updatedEmergency
                }, () => {
                    this.trackFirebaseInfo(eachEmergency.channel_id, eachEmergency.user_id)
                    this.getEmergencyType(eachEmergency.channel_id)
                })

            });

            this.setState({
                registeredUsersCount: homepageResult.registered_users_count,
                registeredUsersCountHighest: calcMaxNum(this.getAllMonths(homepageResult.registered_users_count)),
                emergencyCallsCount: homepageResult.emergency_calls_count,
                emergencyCallsCountHighest: calcMaxNum(this.getAllMonths(homepageResult.emergency_calls_count)),
                emergencyCallCountLowest: calcMinNum(this.getAllMonths(homepageResult.emergency_calls_count)),
                feedCards: homepageResult.feeds,
                serviceNumberCollection: homepageResult.services_number,
                totalEmergency: homepageResult.total_emergency_calls

            })

            this.setState({
                policeNumber: this.state.serviceNumberCollection.police,
                fireBrigadeNumber: this.state.serviceNumberCollection.fire_brigade,
                carTowNumber: this.state.serviceNumberCollection.car_tow,
            })

            localStorage.setItem("police", this.state.policeNumber)
            localStorage.setItem("fireBrigade", this.state.fireBrigadeNumber)
            localStorage.setItem("carTow", this.state.carTowNumber)
            this.setState({ loading: false })
        }

        else if (homepageResult.status === APIResult.INVALID_SESSION) {
            this.enterDashboardLogin()
        }
    }

    updateState(state) {
        if (this._isMounted) {
            this.setState(state);
        }
    }

    render() {
        const { dataToOverview } = this.state
        return (
            <div className="" style={{ height: this.state.loading ? "100vh" : " 100%" }}>
                {this.state.loading ?
                    <div className="loading-bg" /> : null
                }
                {this.state.loading ?
                    <div
                        id='ppwwwoopp'
                        style={{
                            position: "fixed",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100vw",
                            height: this.state.loading ? "100vh" : " 100%",
                            zIndex: 2000,
                            left: 0,
                            top: 0

                        }}>
                        <QueryingIndicator />
                    </div> : null
                }
                {this.state.showEmergencyCardOverview ?
                    <div>
                        <div style={{
                            position: "relative",
                            width: "100vw",
                            height: "100vh",
                            zIndex: 35
                        }}>

                            <div style={{
                                position: "fixed",
                                width: "100vw",
                                height: "100vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                top: 0,
                                left: 0,
                            }}

                                className="emergencyCardOverview-layout">
                                <EmergencyCardOverview
                                    
                                    pageType={dataToOverview}
                                    onProceed={this.enterEmergencyOverview}
                                    info={this.state.currentEmergencyOverview}
                                    police={this.state.policeNumber}
                                    fireBrigade={this.state.fireBrigadeNumber}
                                    carTow={this.state.carTowNumber}
                                />
                            </div>
                        </div>
                    </div> : null
                }


                <div className='home-parent' style={{ marginTop: 16 }}>
                    <CardFrame title="Registered User Count" >
                        <RegisteredUsersCount
                            
                            highest={this.state.registeredUsersCountHighest}
                            data={this.state.registeredUsersCount} />
                    </CardFrame>

                    <CardFrame title="Emergency Calls">
                        <RegisteredUsersCount
                           
                            highest={this.state.emergencyCallsCountHighest}
                            lowest={this.state.emergencyCallCountLowest}
                            data={this.state.emergencyCallsCount}
                            colorCode="red"
                            isRegUserCount={false}
                        /></CardFrame>

                    <CardFrame title="Latest Emergency Calls" showButton={true} icon={Exclamation} enterPage={this.props.enterEmergencyPage}>
                        {this.state.latestEmergency
                            .sort((a, b) => Math.max(b.id - a.id, 0))
                            .map((eachEmergencyInfo, index) => {
                                if (index === 0) {
                                    localStorage.setItem("latestEmergencyId", eachEmergencyInfo.id)
                                }
                                return <div key={index} onClick={() => this.enterEmergencyOverview(eachEmergencyInfo)} style={{ marginBottom: 10, marginTop: 10 }}>
                                    <EmergencyCallCard
                                        key={index}
                                        id={index}
                                        emergencyInfo={eachEmergencyInfo}
                                    />
                                </div>
                            })}
                    </CardFrame>

                    <CardFrame title="Total Emergency Calls" icon={Exclamation} >
                        {Object.entries(this.state.totalEmergency)
                            .sort((a, b) => b[1]["total"] - a[1]["total"])
                            .map((entry, index) => {
                                return <div key={index} style={{ marginBottom: 10, marginTop: 10 }}>
                                    <TotalEmergencyCallsCard
                                        key={index}
                                        id={index + 1}
                                        name={capitalizeFirstLetter(entry[0])}
                                        totalCalls={entry[1]["total"]}
                                        monthlyCalls={entry[1]["calls_per_month"]}
                                        icon={getEmergencyIconFromName(entry[0])}
                                    />
                                </div>
                            })}
                    </CardFrame>

                    <CardFrame title="Latest Feeds" showButton={true} icon={FeedsIcon} enterAddFeeds={this.enterAddFeedsPage} enterPage={this.props.enterFeedPage}>
                        {this.state.feedCards.map((feedCard, index) => {
                            return <div key={index} style={{ marginBottom: 10, marginTop: 10 }}><FeedCard

                                key={index}
                                background={feedCard.feed_cover_image}
                                title={feedCard.feed_title}
                                epochTime={feedCard.feed_datetime}
                                time={feedCard.time}
                                enterEditFeed={() => {
                                    var event = new CustomEvent("addeditfeed", { detail: feedCard });
                                    window.document.dispatchEvent(event);
                                }}
                            />
                            </div>

                        })}
                    </CardFrame>
                </div>
            </div>

        );
    }
}

HomePage.propTypes = {
    buttonTitle: PropTypes.string
}

HomePage.defaultProps = {
    buttonTitle: "More  >"
};

export default HomePage;

