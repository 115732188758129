import React from 'react';
import CardFrame from '../widgets/CardFrame';
import { styled } from '@mui/material/styles';
import { Switch } from '@mui/material';
import IconTextInput from '../widgets/IconTextInput';
import GenericButton from '../widgets/GenericButton';
import { debugPrint } from '../../utilities/Utilities';
import { setServicesNumber } from '../../utilities/requests';
import APIResult from '../../configs/APIResult';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
class SettingsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            police: "",
            fireBrigade: "",
            carTow: "",
            hasDefaultNumber: false,
            rapidNewEmergencyUpdate: false,
            autoEnablePublish: false,
        };

        this.getSetServiceNumbers = this.getSetServiceNumbers.bind(this)
        this.updatePolice = this.updatePolice.bind(this)
        this.updateCarTow = this.updateCarTow.bind(this)
        this.updateFireBrigade = this.updateFireBrigade.bind(this)
        this.switchChanger = this.switchChanger.bind(this)
    }

    componentDidMount() {
        console.log(localStorage.getItem("autoEnablePublish"))
        this.setState({
            autoEnablePublish: localStorage.getItem("autoEnablePublish") === 'true' ? true : false,
            rapidNewEmergencyUpdate: localStorage.getItem("rapidNewEmergencyUpdate") ==='true' ? true : false
        });

        this.setState({
            police: localStorage.getItem("police"),
            fireBrigade: localStorage.getItem("fireBrigade"),
            carTow: localStorage.getItem("carTow")
        });
    }

    async getSetServiceNumbers(policeNumber, fireNumber, carTowNumber) {
        var serviceResult = await setServicesNumber(localStorage.getItem("id"), localStorage.getItem("jwt"), policeNumber, fireNumber, carTowNumber);
        if (serviceResult.status === APIResult.SUCCESS) {
            localStorage.setItem("police", this.state.police)
            localStorage.setItem("fireBrigade", this.state.fireBrigade)
            localStorage.setItem("carTow", this.state.carTow)
        }
    }
    switchChanger(bool) {
        if (bool === true) {
            return 1
        }
        else {
            return 0
        }
    }


    updatePolice(event) {
        this.setState({ police: event.target.value });
    }

    updateFireBrigade(event) {
        this.setState({ fireBrigade: event.target.value });
    }

    updateCarTow(event) {
        this.setState({ carTow: event.target.value });
    }

    render() {
        return (
            <div
                style={{
                    width: "100%",
                    height: " 100vh",
                    display: "flex",
                    justifyContent: "center",
                }}>
                <div style={{
                    fontFamily: "lato",
                    color: "white",
                    fontWeight: "bold",
                    marginTop: 16
                }}>

                    <CardFrame title="Emergency">
                        <div style={{
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            {/* <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingTop: 10,
                                    paddingBottom: 10

                                }}>
                                <div
                                    style={{
                                        fontSize: "16px",
                                        color: "rgba(255, 255, 255, 0.85)",
                                        fontWeight: 500,

                                    }}>
                                    Rapid new emergency update
                                </div>

                                <div>
                                    <IOSSwitch
                                        onChange={e => {
                                            debugPrint(e.target.checked);
                                            localStorage.setItem("rapidNewEmergencyUpdate", e.target.checked)
                                            this.setState({ rapidNewEmergencyUpdate: e.target.checked });
                                        }}
                                        checked={this.state.rapidNewEmergencyUpdate}


                                    />
                                </div>
                            </div> */}

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                                <div style={{
                                    marginTop: 25,
                                    fontSize: "16px",
                                    color: "rgba(255, 255, 255, 0.85)",
                                    fontWeight: 500,
                                }}>
                                    911
                                </div>
                                <div style={{ paddingLeft: "50px" }}>
                                    <IconTextInput
                                        // key={this.state.updatedPolice}
                                        label=""
                                        defaultValue={localStorage.getItem("police")}
                                        onChange={this.updatePolice}
                                        value={this.state.police} />
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                                <div style={{
                                    marginTop: 25,
                                    fontSize: "16px",
                                    color: "rgba(255, 255, 255, 0.85)",
                                    fontWeight: 500,
                                }}>
                                    Fire Brigade
                                </div>
                                <div >
                                    <IconTextInput
                                        label=""
                                        defaultValue={localStorage.getItem("fireBrigade")}
                                        onChange={this.updateFireBrigade}
                                        value={this.state.fireBrigade} />
                                </div>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                                <div style={{
                                    marginTop: 25,
                                    fontSize: "16px",
                                    color: "rgba(255, 255, 255, 0.85)",
                                    fontWeight: 500,
                                }}>
                                    Car Tow
                                </div>
                                <div style={{ paddingLeft: "50px" }}>
                                    <IconTextInput
                                        // key={this.state.updatedPolice}
                                        label=""
                                        defaultValue={localStorage.getItem("carTow")}
                                        onChange={this.updateCarTow}
                                        value={this.state.carTow} />
                                </div>
                            </div>

                        </div>

                    </CardFrame>
                    {/* <div style={{ marginTop: 10 }}>
                        <CardFrame title="Feeds" style={{}}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingTop: 10,
                                    paddingBottom: 10

                                }}>
                                <div
                                    style={{
                                        fontSize: "16px",
                                        color: "rgba(255, 255, 255, 0.85)",
                                        fontWeight: 500,
                                    }}>
                                    Auto Enable Publish
                                </div>

                                <div >
                                    <IOSSwitch
                                        onChange={e => {
                                            debugPrint(e.target.checked);
                                            localStorage.setItem("autoEnablePublish", e.target.checked)
                                            this.setState({ autoEnablePublish: e.target.checked });
                                        }}
                                        checked={this.state.autoEnablePublish}
                                    />
                                </div>
                            </div>

                        </CardFrame>
                    </div> */}
                        <div style={{ marginTop: 10 }} >
                            <GenericButton
                                onClick={() => this.getSetServiceNumbers(this.state.police, this.state.fireBrigade, this.state.carTow)}
                                fullWidth={true}
                                color="#C4C4C4"
                                borderRadius="10px"
                                label="Save Settings"
                                fontSize={18}
                            />
                        </div>
                </div >

            </div >

        );
    }
}

export default SettingsPage;

