import React from 'react';
import FeedCard from '../widgets/FeedCard';
import CardFrame from '../widgets/CardFrame';
import {feedPageInfo, addEditFeedInfo} from '../../utilities/requests';
import APIResult from '../../configs/APIResult';
import AddFeedsIcon from "../../img/icon/AddFeedsIcon.svg"
import QueryingIndicator from '../widgets/QueryingIndicator';
import "./EmergencyPage.css";
import PageNavigation from '../widgets/PageNavigation';
import {DEFAULT_FEED_INFO} from '../../configs/Default';
import {debugPrint} from '../../utilities/Utilities';

class FeedsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            feeds: [],
            newFeed: DEFAULT_FEED_INFO
        };

        this.enterAddFeeds = this
            .enterAddFeeds
            .bind(this);
        this.enterDashboardLogin = this
            .enterDashboardLogin
            .bind(this);
        this.getFeedPageInfo = this
            .getFeedPageInfo
            .bind(this);
        this.addEditFeedInfo = this
            .addEditFeedInfo
            .bind(this);

        this.feedLimit = 50;
        this.currentPage = 1;
        this.publish = 0;
        this.coverImage = "";
    }

    componentDidMount() {
        this.getFeedPageInfo();
    }

    async getFeedPageInfo() {
        this.setState({loading: true})
        if (localStorage.getItem("id") === null && localStorage.getItem("jwt") === null) {
            this.enterDashboardLogin()
        }

        var feedPageResult = await feedPageInfo(localStorage.getItem("id"), localStorage.getItem("jwt"), "", this.feedLimit, this.currentPage);
        if (feedPageResult.status === APIResult.SUCCESS) {
            this.setState({feeds: feedPageResult.feeds})
            this.setState({loading: false})
        }
    }

    async addEditFeedInfo(publish, id, coverImage, title, content, type) {
        var editFeedPageResult = await addEditFeedInfo(localStorage.getItem("id"), localStorage.getItem("jwt"), publish, id, coverImage, title, content, type);
        if (editFeedPageResult.status === APIResult.SUCCESS) {
            debugPrint("Feed Page API Successful")

        } else {
            debugPrint("Feed Page API failed")
        }
    }

    enterAddFeeds() {
        window.location.href = `/addfeed`;
    }

    enterDashboardLogin() {
        window.location.href = `/dashboardlogin`
    }

    render() {
        return (
            <div>
                <div
                    style={{
                    width: "100%",
                    height: this.state.loading
                        ? "100vh"
                        : " 100%",
                    paddingBottom: 200
                }}>
                    <div style={{
                        marginTop: 16
                    }}>

                        <CardFrame
                            title="Latest Feeds"
                            icon={AddFeedsIcon}
                            enterAddFeeds={() => {
                            var event = new CustomEvent("addeditfeed", {detail: this.state.newFeed});
                            window
                                .document
                                .dispatchEvent(event);
                        }}>

                            <div className='home-parent'>
                                {this
                                    .state
                                    .feeds
                                    .map((feed, index) => {
                                        return <div key={index}><FeedCard
                                            key={index}
                                            id={feed.feed_id}
                                            background={feed.feed_cover_image}
                                            title={feed.feed_title}
                                            epochTime={feed.feed_datetime}
                                            time={feed.time}
                                            enterEditFeed={() => {
                                            var event = new CustomEvent("addeditfeed", {detail: feed});
                                            window
                                                .document
                                                .dispatchEvent(event);
                                        }}/>
                                        </div>

                                    })}
                            </div>

                            {/* <div
                                style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "2em"
                            }}><PageNavigation
                                onPageChange={this.UpdateData}
                                total={(Math.ceil(Math.max(1, this.state.totalOnGoingPages) / this.itemPerPage))}/></div> */}
                        </CardFrame>
                    </div>
                    {this.state.loading
                        ? <div className="loading-bg"/>
                        : null
}
                    {this.state.loading
                        ? <div
                                style={{
                                position: "fixed",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100vw",
                                height: "100vh",
                                zIndex: 2000,
                                left: 0,
                                top: 0
                            }}>
                                <QueryingIndicator/>
                            </div>
                        : null
}
                </div>

            </div>
        );
    }
}

FeedsPage.propTypes = {}

FeedsPage.defaultProps = {};

export default FeedsPage;
