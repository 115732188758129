import React from 'react';
import "./DashboardPage.css";
import { IconButton } from '@material-ui/core';
import NavigationButton from '../widgets/NavigationButton';
import NotificationIcon from '../../img/icon/NotificationIcon.svg';
import FeedsIconOff from '../../img/icon/FeedsOff.svg';
import FeedsIconOn from '../../img/icon/FeedsIconOn.svg';
import SettingsIconOff from '../../img/icon/SettingsIcon.svg';
import SettingsIconOn from '../../img/icon/SettingsOn.svg';
import EmergencyIconOn from '../../img/icon/NewEmergencyOn.svg';
import EmergencyIconOff from '../../img/icon/EmergencyOff.svg';
import HomeIconOff from '../../img/icon/HomeOff.svg';
import HomeIconOn from '../../img/icon/HomeOn.svg';
import EmergencyPage from './EmergencyPage';
import HomePage from './HomePage';
import FeedsPage from './FeedsPage';
import SettingsPage from './SettingsPage';
import NotificationAlert from '../widgets/NotificationAlert';
import AddFeed from './AddFeedPage';
import { checkNewEmergency } from '../../utilities/requests';
import APIResult from '../../configs/APIResult';
import EmergencyCardOverview from '../widgets/EmergencyCardOverview';
import { DEFAULT_EMERGENCY_VICTIM_INFO } from '../../configs/Default';
import firestoreDB from '../../utilities/Firebase';
import { doc, onSnapshot, } from "firebase/firestore";
import { debugPrint } from '../../utilities/Utilities';

class DashboardPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showEmergencyCardOverview: false,
            currentEmergencyOverview: DEFAULT_EMERGENCY_VICTIM_INFO,
            inEmergency: false,
            addEditFeedMode: false,
            feedData: [],
            previousPage: -1,
            currentPage: 1,
            visible: 1,
            showNotification: false,
            unread: 0,
            latestNotification: [],
            latestEmergency: [],

        };

        this.allPages = [
            {
                id: 1,
                label: "Home Page",
                icon1: HomeIconOn,
                icon2: HomeIconOff,
                display: true
            }, 
            // {
            //     id: 2,
            //     label: "Emergency Page",
            //     icon1: EmergencyIconOn,
            //     icon2: EmergencyIconOff,
            //     display: true
            // },
             {
                id: 2,
                label: "Feeds Page",
                icon1: FeedsIconOn,
                icon2: FeedsIconOff,
                display: true
            },
             {
                id: 3,
                label: "Settings",
                icon1: SettingsIconOn,
                icon2: SettingsIconOff,
                display: true
            }
        ];
        this._isMounted = false;
        this.navigationBarThickness = 80;

        this.clickNotification = this
            .clickNotification
            .bind(this)
        this.getTitle = this
            .getTitle
            .bind(this)
        this.enterEmergencyOverview = this
            .enterEmergencyOverview
            .bind(this)
        this.getLatestNotification = this
            .getLatestNotification
            .bind(this);
        this.getPage = this
            .getPage
            .bind(this);
        this.updatePage = this
            .updatePage
            .bind(this);
        this.getUnreadNotification = this
            .getUnreadNotification
            .bind(this)
        this.getUnreadNotificationText = this
            .getUnreadNotificationText
            .bind(this)
        this.clickNotification = this
            .clickNotification
            .bind(this)
        this.enterAddEditFeedsPage = this
            .enterAddEditFeedsPage
            .bind(this);
        this.getDashboard = this
            .getDashboard
            .bind(this);
        this.getAddEditFeedPage = this
            .getAddEditFeedPage
            .bind(this);
        this.trackFirebaseInfo = this
            .trackFirebaseInfo
            .bind(this)
        this.getEmergencyType = this
            .getEmergencyType
            .bind(this)
    }

    async getEmergencyType(channelID) {

        onSnapshot(doc(firestoreDB, "emergency", channelID, "emergency_info", "host"), (doc) => {

            var allEmergency = this.state.latestEmergency
            var updatedEmergency = [];
            allEmergency.forEach(eachEmergency => {
                if (eachEmergency.channel_id === channelID) {
                    if (doc.data() !== undefined) {

                        eachEmergency.emergencyType = doc.data().emergency_type;
                    }
                }
                updatedEmergency.push(eachEmergency);

            });

            this.setState({ latestEmergency: updatedEmergency });
        });
    }

    async trackFirebaseInfo(channelID, userID) {

        onSnapshot(doc(firestoreDB, "emergency", channelID, "location", userID.toString()), (doc) => {

            var allEmergency = this.state.latestEmergency
            var updatedEmergency = [];
            allEmergency.forEach(eachEmergency => {
                if (eachEmergency.channel_id === channelID) {
                    if (doc.data() !== undefined) {

                        eachEmergency.longitude = doc.data().long;
                        eachEmergency.latitude = doc.data().lat;
                    }
                }
                updatedEmergency.push(eachEmergency);
            });
            this.setState({ latestEmergency: updatedEmergency });
        });
    }

    getTitle(page) {
        var title = ""
        if (page === 1) {
            title = "Overview"
        }
        // else if (page === 2) {
        //     title = "Emergency Calls"
        // }
        else if (page === 2) {
            title = "Feeds"
        }
        else if (page === 3) {
            title = "Settings"
        }
        return title
    }

    enterEmergencyOverview(emergencyInfo) {
        this.setState({ showEmergencyCardOverview: !this.state.showEmergencyCardOverview, currentEmergencyOverview: emergencyInfo })
        this.clickNotification()
    }

    getPage() {
        switch (this.state.currentPage) {
            default:
                return null;
            case 0:
                return (
                    <div><HomePage />
                    </div>
                );
            case 1:
                return (
                    <div><HomePage
                        enterEmergencyPage={() => this.updatePage(2)}
                        enterFeedPage={() => this.updatePage(3)} /></div>
                );
            // case 2:
            //     return (
            //         <div><EmergencyPage /></div>
            //     );
            case 2:
                return (
                    <div><FeedsPage /></div>
                );
            case 3:
                return (
                    <div><SettingsPage /></div>
                );
        }
    }

    updatePage(index) {
        // debugPrint(`Going to ${index}`)
        this.updateState({ previousPage: this.state.currentPage, currentPage: index })
    }

    updateState(state) {
        if (this._isMounted) {
            this.setState(state);
        }
    }

    componentDidMount() {
        this.getLatestNotification()

        if (localStorage.getItem("rapidNewEmergencyUpdate") === "true") {
            this.listenToNotification = setInterval(this.getLatestNotification, 15000)

        } else {
            this.listenToNotification = setInterval(this.getLatestNotification, 60000)

        }

        this._isMounted = true
        window.document.addEventListener("addeditfeed", this.enterAddEditFeedsPage);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    clickNotification() {
        this.setState({
            showNotification: !this.state.showNotification
        })
    }

    getUnreadNotification() {
        return this.state.latestEmergency.length
    }

    enterAddEditFeedsPage(data) {
        debugPrint("gotten this event with data", data.detail)
        this.setState({ feedData: data.detail, addEditFeedMode: true })
    }

    getUnreadNotificationText() {
        let unread = this.getUnreadNotification()

        if (unread === 0) {
            return "";
        } else if (unread < 10) {
            return unread;
        } else {
            return "9+";
        }

    }
    // getUnreadNotification() > 9 ? "9+" : getUnreadNotification()

    getAddEditFeedPage() {
        return <AddFeed data={this.state.feedData} />
    }

    async getLatestNotification() {

        var notificationResult = await checkNewEmergency(localStorage.getItem("id"), localStorage.getItem("jwt"), localStorage.getItem("latestEmergencyId"));
        if (notificationResult.status === APIResult.SUCCESS) {
            var updatedEmergency = [];
            // debugPrint("notification API working")
            // this.setState({ latestNotification: notificationResult.latest_emergency })
            notificationResult.latest_emergency.forEach(eachEmergency => {
                eachEmergency.longitude = 0;
                eachEmergency.latitude = 0;
                eachEmergency.emergencyType = 0
                updatedEmergency.push(eachEmergency);
                this.setState({
                    latestEmergency: updatedEmergency
                }, () => {
                    this.trackFirebaseInfo(eachEmergency.channel_id, eachEmergency.user_id)
                    this.getEmergencyType(eachEmergency.channel_id)
                })

            });
        }

    }

    getDashboard() {
        return <div
            style={{
                width: "100vw",
                background: "black",
                position: "relative"
            }}>
            <div
                className='center-div'
                style={{
                    width: "100%",
                    zIndex: 50,
                    boxShadow: "4px 0px 8px rgba(0, 0, 0, 0.125)",
                    boxSizing: "border-box",
                    backgroundColor: "#262626",
                    color: "white",
                    top: 0,
                    left: 0,
                    position: "fixed",
                    height: 48
                }}>
                {this.state.showEmergencyCardOverview ?
                    <div>
                        <div style={{
                            position: "relative",
                            width: "100vw",
                            height: "100vh",
                            zIndex: 35
                        }}>

                            <div style={{
                                position: "fixed",
                                width: "100vw",
                                height: "100vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                top: 0,
                                left: 0,
                            }}

                                className="emergencyCardOverview-layout">
                                <EmergencyCardOverview
                                    onProceed={this.enterEmergencyOverview}
                                    info={this.state.currentEmergencyOverview}
                                // police={this.state.policeNumber}
                                // fireBrigade={this.state.fireBrigadeNumber}
                                // carTow={this.state.carTowNumber}
                                />
                            </div>
                        </div>
                    </div> : null
                }
                <div
                    className='max-width-adapt'
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%"
                    }}>
                    <IconButton >
                        <img
                            src={NotificationIcon}
                            alt="Home"
                            style={{
                                height: "auto",
                                opacity: 0
                            }} />
                    </IconButton>
                    <text className='page-title'>
                        {this.getTitle(this.state.currentPage)}
                    </text>
                    {/* {*TODO*} */}
                    <div >
                        <IconButton onClick={this.clickNotification}>
                            <img
                                src={NotificationIcon}
                                alt="Home"
                                style={{
                                    height: "auto",
                                    position: "relative"
                                }} /> {this.state.latestEmergency.length > 0
                                    ? <div
                                        style={{
                                            position: "absolute",
                                            background: " #FF0000",
                                            borderRadius: "25px",
                                            height: "12px",
                                            width: "12px",
                                            top: 10,
                                            right: 10
                                        }}>
                                        <div
                                            style={{
                                                fontSize: "7px",
                                                color: "white",
                                                fontFamily: "lato",
                                                marginTop: 2
                                            }}>
                                            {this.getUnreadNotificationText()}
                                        </div>
                                    </div>
                                    : null}
                        </IconButton>
                    </div>
                </div>
            </div >
            <div className='center-div'>
                <div className='max-width-adapt content below-top-bar'>
                    {this.getPage()}
                </div>
            </div>

            <div className='bottom-navigation'>
                <div className='bottom-navigation-frame'>
                    {this
                        .allPages
                        .map((eachPage, index) => {
                            return (<NavigationButton
                                key={index}
                                enable={this.state.currentPage === eachPage.id}
                                image={this.state.currentPage === eachPage.id
                                    ? eachPage.icon1
                                    : eachPage.icon2}
                                onClick={() => this.updatePage(eachPage.id)} />);
                        })}
                </div>
            </div>

            {this.state.showNotification
                ? <div
                    onClick={this.clickNotification}
                    className='notification-layout center-div'>
                    <div className='max-width-adapt below-top-bar relative'>
                        <div className='notification-group-backdrop'>
                            {this
                                .state
                                .latestEmergency
                                .slice(0, 5)
                                .map((item, index) => {
                                    return <div>
                                        <div onClick={() => this.enterEmergencyOverview(item)}
                                            style={{
                                                margin: 10,
                                                cursor: "pointer"
                                            }}>
                                            <NotificationAlert
                                                name={item.name}
                                                key={index}
                                                icon={item.profile_picture}
                                                title={item.title}
                                                // message={item.message}
                                                showDivider={index === 0
                                                    ? false
                                                    : true} />
                                        </div>
                                    </div>
                                })}
                        </div>

                    </div>
                </div>
                : null
            }
        </div >;
    }

    render() {
        return this.state.addEditFeedMode
            ? this.getAddEditFeedPage()
            : this.getDashboard();
    }
}

export default DashboardPage;
