import { initializeApp } from "firebase/app";
import { getFirestore} from "@firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD8hBf760n7ViKwN5zdrorsQjY0eevH9Ls",
  authDomain: "siren-2906f.firebaseapp.com",
  databaseURL: "https://siren-2906f-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "siren-2906f",
  storageBucket: "siren-2906f.appspot.com",
  messagingSenderId: "1028785806924",
  appId: "1:1028785806924:web:6e96952ffde0b7d55a9ee9"
};

const firebaseApp = initializeApp(firebaseConfig);
const firestoreDB = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
signInWithEmailAndPassword(auth, "backend-server@siren.the-oaks.my", "srn-auth!88")
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    console.log("user is this ", user)
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
export default firestoreDB;