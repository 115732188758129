import APIPaths from "../configs/APIPaths";
import APIResult from "../configs/APIResult";
import APIHeaders from "../configs/APIHeaders";
import { createBrowserHistory } from 'history';
import { debugPrint } from "./Utilities";


async function callAPI(body, target, defaultReturn) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': APIHeaders.contentType,
            'apikey': APIHeaders.apiKey
        },
        body: JSON.stringify(body)
    };

    debugPrint(target);
    debugPrint(body);

    var proceed = true;
    Object.values(body).forEach(param => {
        if (param === null || param === undefined) {
            proceed = false;
        }
    });

    if (proceed) {
        await new Promise(resolve => setTimeout(resolve, 500));
        return await fetch(target, requestOptions)
            .then(response => response.json())
            .then((responseData) => {
                // debugPrint(responseData);
                // console.log(responseData);
                try {
                    if (responseData.status === APIResult.INVALID_SESSION) {
                        createBrowserHistory().push('/dashboardlogin?to=1');
                        window
                            .location
                            .reload();
                    }
                } catch (e) {
                    console.log(e);
                }
                return responseData;
            })
            .catch(error => {
                console.warn(error);
                return defaultReturn;
            });
    }
    else {
        return defaultReturn;
    }
}

export async function passwordRequestReset(user_email) {
    var body = {
        user_email
    };
    var target = APIPaths
        .apiEndPoint
        .concat(APIPaths.resetPasswordRequest);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: []
    });
}

export async function resetPassword(datetime, token, password) {
    var body = {
        datetime: datetime,
        token: token,
        new_pw: password
    };
    var target = APIPaths
        .apiEndPoint
        .concat(APIPaths.resetPassword);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-"
    });
}

export async function passwordLinkValid(datetime, token) {
    var body = {
        datetime: datetime,
        token: token,
    };
    var target = APIPaths
        .apiEndPoint
        .concat(APIPaths.passwordLinkValid);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-"
    });
}

export async function login(userID, password) {
    var body = {
        u: userID,
        p: password,
    };
    var target = APIPaths
        .apiEndPoint
        .concat(APIPaths.login);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-"
    });
}

export async function homepageInfo(userID, jwt) {
    var body = {
        u: userID,
        j: jwt,
    };
    var target = APIPaths
        .apiEndPoint
        .concat(APIPaths.homepageInfo);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-"
    });
}

export async function emergencyPageInfo(userID, jwt, type, limit, page) {
    var body = {
        u: userID,
        j: jwt,
        t: type,
        l: limit,
        p: page
    };
    var target = APIPaths
        .apiEndPoint
        .concat(APIPaths.emergencyPageInfo);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-"
    });
}

export async function feedPageInfo(userID, jwt, keyword, limit, page) {
    var body = {
        u: userID,
        j: jwt,
        k: keyword,
        l: limit,
        p: page
    };
    var target = APIPaths
        .apiEndPoint
        .concat(APIPaths.feedPageInfo);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-"
    });
}

export async function deleteFeed(userID, jwt, id) {
    var body = {
        u: userID,
        j: jwt,
        id: id
    };
    var target = APIPaths
        .apiEndPoint
        .concat(APIPaths.deleteFeed);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-"
    });
}

export async function addEditFeedInfo(userID, jwt, publish, id, coverImage, title, content, type) {
    var body = {
        u: userID,
        j: jwt,
        publish: publish,
        id: id,
        cover_image: coverImage,
        title: title,
        content: content,
        type: type
    };
    var target = APIPaths
        .apiEndPoint
        .concat(APIPaths.addEditFeedInfo);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-"
    });
}

export async function setServicesNumber(u, j, police, fire, car) {
    var body = {
        u: u,
        j: j,
        police: police,
        fire_brigade: fire,
        car_tow: car
    };
    var target = APIPaths
        .apiEndPoint
        .concat(APIPaths.setServicesNumber);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: []
    });
}

export async function checkNewEmergency(u, j, l) {
    var body = {
        u: u,
        j: j,
        l: l

    };
    var target = APIPaths
        .apiEndPoint
        .concat(APIPaths.checkNewEmergency);

    return callAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: []
    });
}