const threshold = {
    mobile: 450,
    fullWidthMobile: 650,
    dashboardPadding: "4px 4px",
    sidebar: 100,
    productCountPerPage: 5,
    orderCountPerPage: 12,
    paymentHistoryPerPage: 3,
    apiDelay: 500,
    compactSize: 900
}

export default threshold;