import React from "react";
import PropTypes from 'prop-types';
import "./KH.css";
import "../common.css";
import PageNavigation from "../widgets/PageNavigation";
import { debugPrint } from "../../utilities/Utilities";

class KH extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            totalPage: this.props.total,
            backgroundColor: "",
            fontColor: ""
        };

        this.prevPage = this
            .prevPage
            .bind(this);
        this.nextPage = this
            .nextPage
            .bind(this);

        this.first = React.createRef();
        this.prev = React.createRef();
        this.next = React.createRef();
        this.last = React.createRef();

        this.range = 1;
    }

    getShowingPages() {
        var totalPages = [];
        var lowest = this.state.currentPage - this.range;
        var highest = this.state.currentPage + this.range;

        for (var j = 1; j <= this.range; j++) {
            totalPages.push(this.state.currentPage + j);
            totalPages.push(this.state.currentPage - j);
        }

        totalPages.push(this.state.currentPage);
        var updatedTotalPages = [];
        totalPages.forEach(eachPage => {
            if (eachPage <= 0) {
                updatedTotalPages.push(highest + Math.abs(eachPage - 1))
            } else if (eachPage > this.state.totalPage) {
                updatedTotalPages.push(lowest - Math.abs(eachPage - this.state.totalPage))
            } else {
                updatedTotalPages.push(eachPage);
            }
        });
        return updatedTotalPages.sort(function (a, b) {
            return a - b;
        });
    }

    componentDidMount()
    {
        debugPrint(this.props.enableFirstLastButton)
    }

    onHover() {
        this.setState({backgroundColor: "#EE4139", fontColor: "white"})
    }

    onLeave() {
        this.setState({backgroundColor: "none", fontColor: "#00000050"})
    }

    nextPage() {
        this.setState({
            currentPage: Math.min(this.state.currentPage + 1, this.state.totalPage)
        })
    }

    prevPage() {
        this.setState({
            currentPage: Math.max(this.state.currentPage - 1, 1)
        })
    }

    jumpToPage(page)
    {
        this.setState({currentPage: page});
    }

    render() {
        return (
            <div className="pagination-parent" style={{marginTop: 32}}>
                <PageNavigation/>
            </div>
        )
    }
}

KH.propTypes = {
    onPageChange: PropTypes.func,
    enableFirstLastButton: PropTypes.bool,
    total:PropTypes.number
}

KH.defaultProps = {
    onPageChange: function (currentPage) {
        debugPrint("Current page is -> ", currentPage);
    },
    enableFirstLastButton: true,
    total:25
};

export default KH;