const APIPaths = {
    apiEndPoint: 'https://sirenapi.the-oaks.my/v1/',

    resetPasswordRequest: 'user/password_reset_request',
    passwordLinkValid: 'user/password_reset_link_valid',
    resetPassword: "user/password_reset",
    login: "admin/login",
    homepageInfo: "admin/get_homepage_info",
    emergencyPageInfo: "admin/get_emergency_info",
    feedPageInfo: "admin/get_feeds_info",
    deleteFeed: "admin/delete_feed",
    addEditFeedInfo: "admin/add_edit_feed",
    setServicesNumber: "admin/set_services_number",
    checkNewEmergency: "admin/check_new_emergency"
};

export default APIPaths;