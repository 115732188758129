import PropTypes from 'prop-types';
import React from 'react';
import Backdrop from "../../img/icon/Backdrop.svg"
import EditIcon from "../../img/icon/EditIcon.svg"
import "./FeedCard.css";
import { epochToDateTime } from '../../utilities/Utilities';
import SirenDefault from "../../img/icon/siren_default.png"
class FeedCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            useDefault: false
        };
        this.useDefault = this.useDefault.bind(this)

    }

    useDefault(image) {
        if ((image.startsWith("https://") || image.startsWith("http://")) && image.replace(" ", "") !== "") {
            this.setState({ useDefault: true })
        }
    }
    render() {
        return (

            <div onClick={this.props.enterEditFeed} className='feed-card' >
                <img className='feed-bg' src={this.props.background === "https://siren.the-oaks.my" ? this.props.defaultImage : this.props.background} alt="feed-bg" />
                <div className='feed-card-overlay' />
                <div>
                    <div className='feed-card-edit-button' >
                        <img
                            className="feed-to-upload-image"
                            alt=""
                            src={EditIcon} />
                        <div
                            style={{
                                color: "#EE4139",
                                fontFamily: "Lato",
                                fontSize: "12px",
                                marginTop: "2px",
                                marginRight: "5px"
                            }}>
                            Edit
                        </div>
                    </div>
                </div>

                <div className='feed-card-info'>
                    <div
                        className='feed-card-datettime'>
                        {epochToDateTime(this.props.epochTime)}
                    </div>

                    <div className='feed-card-title'>
                        {this.props.title}
                    </div>

                </div>
            </div >
        );
    }
}

FeedCard.propTypes = {
    day: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    title: PropTypes.string,
    background: PropTypes.string,
    epochTime: PropTypes.number,
    enterEditFeed: PropTypes.func,
    defaultImage: PropTypes.string

}

FeedCard.defaultProps = {
    day: "Friday",
    date: "1st April 2022",
    time: "10:54 AM",
    title: "3 Children Died from Overdose of what seem to be Heroine3 Children Died from Overdose of what seem to be Heroine",
    background: Backdrop,
    defaultImage: SirenDefault,
    epochTime: 1642061781,
    enterEditFeed: function () {
    }

};
export default FeedCard;